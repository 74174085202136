import { environment } from 'src/environments/environment';
const domain = '';
export const ApiUrlss = {
  getProjectWidget: `${domain}/path`,
};
export class ApiUrls {


  //Global Search
  public static getAzureSearch = environment.azureSearch + 'indexes/blueprint-globalsearch/docs/search?api-version=2020-06-30';

  public static getAutocompleteSuggestions = environment.azureSearch + 'indexes/blueprint-globalsearch/docs/suggest?api-version=2020-06-30';

  // public static generatedLayoutedQuote = environment.workflows + "generatequote"
  public static generatedLayoutedQuote = environment.workflows + 'lquoteconvertpdf';
  public static getcommercialquote = environment.offerBaseURL;

  public static uploadGenerateQuote = environment.offerBaseURL + '/GenerateLayoutQuotationByCommercialQuotationNumber';
  public static generateQuotation = environment.offerBaseURL + '/GenerateLayoutQuotationByCommercialQuotationNumberV1';
  public static saveFinalQuoteDetails = environment.offerBaseURL + '/AddFinalQuotation';

  public static deleteMachineLimitImage = environment.riskassessmentBaseUrl + 'deletemachinemedia';
  public static deleteMediaFile = environment.riskassessmentBaseUrl + 'deleteMediaFile';

  public static getProjectAuditLogs = environment.projectBaseUrl + 'auditlogs';

  public static generateRiskAssessmentReport = environment.userBaseUrl + 'riskassessmentsvc/GenerateRiskAssessmentReportV4';

  public static getFinalQuoteDblist = environment.offerBaseURL;


  //https://schprojectdev.azurewebsites.net/api/project/
  // https://blueprint-d.dnaofsafety.net/api/ordersvc//OID20211207-03/orders
  public static deleteMachines = environment.offerBaseURL + '/mastermachinesvc/mastermachine';
  public static deleteRoadmap = environment.riskassessmentBaseUrl + 'deleteroadmap';
  public static deleteRoadmapSectionsData = environment.riskassessmentBaseUrl + 'deleteroadmapdata';
  public static deleteSistemaReport = environment.riskassessmentBaseUrl + 'deletesistemareport';
  public static deleteResource = environment.riskassessmentBaseUrl + 'DeleteResource';
  public static deleteHazard = environment.riskassessmentBaseUrl + 'deletehazard';
  public static finalRaReport = environment.riskassessmentBaseUrl + 'finalreport';
  public static saveRaReport = environment.riskassessmentBaseUrl + 'SaveRAReport';
  public static getRAReports = environment.riskassessmentBaseUrl + 'GetRAReports';


  public static deleteOpportunity = environment.offerBaseURL + '/deleteOpportunity';
  public static getProjectWidget = environment.projectBaseUrl + 'projects';
  public static getProjectChartsData = environment.projectBaseUrl;
  public static getOffertChartsData = environment.offerBaseURL;
  public static addMachines = environment.projectBaseUrl + 'addmachine';
  public static getProjectBudgetInfo = environment.projectBaseUrl;
  public static getLogActualHoursList = environment.offerBaseURL + '/service';
  public static getMachineList = environment.masterMachineSvcBaseUrl + '/filteredmachines';
  public static addNewMachines = environment.offerBaseURL + '/service/addmachines';
  public static editMachines = environment.offerBaseURL + '/service/update';
  public static getProjectDetail =
    environment.projectBaseUrl + 'project/';

  public static getRegionByCompanyCode = environment.offerBaseURL + '/service/';

  public static getCompanyDetails =
    environment.projectBaseUrl + 'companydetails/';
  public static getTaskManagers = environment.projectBaseUrl + `taskmanager/tasks`;
  public static getProjectManagementEffortMetrics =
    environment.projectBaseUrl + 'projectmetrics';
  public static getProjectDetailServiceOrder =
    environment.projectBaseUrl;
  public static getProjectDetailServiceListBasedonServiceOrderAndType =
    environment.projectBaseUrl + 'service';
  public static getServiceTypeByOrderId = environment.projectBaseUrl;
  public static getProjectDetailMachinesListBasedonServiceOrderAndType =
    environment.projectBaseUrl + `machines`;
  public static updateLogActualHours = environment.offerBaseURL + `/service/addactualhours`;
  public static getSearchedCustomer = environment.customerBaseUrl + `/search`;
  public static getCustomerContactInfo = environment.customerBaseUrl;
  public static createProject =
    environment.projectBaseUrl + 'project';
  public static createOffer =
    environment.offerBaseURL + '/create';
  public static editProject =
    environment.projectBaseUrl + 'update';

  public static getOrderManagementOrderTrackerDeatils =
    environment.orderBaseUrl + 'orders';
  public static getOrderManagementOrderDeatil =
    environment.orderBaseUrl;

  public static getOrdderChartsData = environment.orderBaseUrl;

  public static getOrderManagementEffortMetrics =
    environment.orderBaseUrl + 'effortsmetrics';

  //Offer Management API END
  public static getOfferManagementDetails =
    environment.offerBaseURL + '/v1';

  public static viewOfferDetailsById =
    environment.offerBaseURL + '/offerdetails/';

  public static getRiskResssesmentMachinebyServiceId =
    environment.offerBaseURL + '/service/';

  public static createNewService =
    environment.offerBaseURL + '/service/create';


  public static viewOfferServiceDetails =
    environment.offerBaseURL + '/offerservicedetails/';

  public static getOfferMachineDetails =
    environment.offerBaseURL + '/offermachinedetails/';

  public static deleteOffer =
    environment.offerBaseURL + '/deleteoffer';

  public static deleteOfferservice =
    environment.offerBaseURL + '/deleteofferservice';
  public static getOfferStatusService =
    environment.offerBaseURL + '/Get';

  public static getMachineRoadmaps = environment.offerBaseURL + '/service/';

  public static offerEffortmetrics =
    environment.offerBaseURL + '/effortmetrics';

  public static searchServiceCustomerName =
    environment.offerBaseURL;

  public static offerCustomerInfo =
    environment.customerBaseUrl;

  public static customerProjectIdByCustName =
    environment.offerBaseURL + '/getprojectsbycustomerid/';

  public static searchMachineMaster =
    environment.offerBaseURL + '/mastermachinesvc/SearchMasterMachines';

  public static searchCustomerMachines = environment.offerBaseURL + '/mastermachinesvc/';
  public static machinesFromLibrary = environment.offerBaseURL + '/mastermachinesvc/searchcustomermachines';

  public static searchPreviousProjectMachineMasterWithIndex =
    environment.offerBaseURL + '/mastermachinesvc/SearchProjectMachineDetails';

  //Offer Management API END

  //bucket lists
  public static bucketLists =
    environment.bucketSvcBaseUrl;
  public static createBucket =
    environment.bucketSvcBaseUrl + 'Bucket';
  public static getMachinesListsInAddedBucket = environment.bucketMachineSvcBaseUrl;
  public static addmachinesToExistingBucket = environment.bucketMachineSvcBaseUrl + 'Machine/Add';
  public static editBucket = environment.bucketSvcBaseUrl + 'Buckets/Update';
  public static deleteBuckets = environment.bucketSvcBaseUrl + 'Buckets/Delete';
  public static deleteBucketMachine = environment.bucketMachineSvcBaseUrl;

  //upload Functionlaity
  public static uploadLibrary = environment.libraryMastermachineSvcBaseUrl + 'machinefileupload';//'https://offerapiservicedev.azurewebsites.net/api/MasterMachine/FileUploadForLibraryManagent';

  public static getNotification = environment.notificationBaseUrl + 'NotificationReceived';//'https://schsignalrnotifications.azurewebsites.net/notify'

  //upload Machine Details Api
  public static uploadMasterData = environment.offerBaseURL + '/mastermachinesvc/MasterMachineFileUploadJson';
  // 'https://offerapiservicedev.azurewebsites.net/api/MasterMachine/MasterMachineFileUploadJson'

  // RoadMap
  public static uploadRoadMap = environment.offerLibraryBaseURL + '/RoadMap';

  //ControlMeasure
  public static counterMeasure = environment.offerLibraryBaseURL + '/CounterMeasure';
  public static getCounterMeasure =
    environment.offerLibraryBaseURL + '/CounterMeasure';
  public static updateDeleteControlMeasures = environment.offerLibraryBaseURL + '/UpdateDeleteDataCounterMeasure';
  public static getControlMeasure =
  environment.offerLibraryBaseURL + '/ControlMeasure';

  // Intial Measure
  public static IntialHazard = environment.offerLibraryBaseURL + '/InitialHazard';
  public static getInitialMeasure = environment.offerLibraryBaseURL + '/InitialHazard';
  public static updateDeleteInitialHazard = environment.offerLibraryBaseURL + '/UpdateDeleteDataInitialHazard';

  //Bookmark
  public static onAddBookmark = environment.libraryRoadmapSvcBaseUrl + 'library/';

  public static saveToTemplate =
    environment.offerLibraryBaseURL + '/TemplateRoadMap';

  public static deleteTemplateRoadmap = environment.offerLibraryBaseURL + '/DeleteTemplateRoadmapV1';


  public static onRemoveBookmark = environment.libraryRoadmapSvcBaseUrl + 'deletebookmark';

  //MachineLibrary List
  public static getMachineLibrary =
    environment.offerBaseURL + '/mastermachinesvc/SearchMasterMachines/';

  public static machineAttirbutesData =
    environment.offerBaseURL + '/mastermachinesvc/MachineAttribute';

  public static getMachinesReferrncesList =
    environment.offerBaseURL + '/mastermachinesvc/machinereferences';

  public static getRoadMapLibrary =
    environment.libraryBaseUrl + 'roadmapsvc/roadmap';

  public static getSelectedBookmarkData = environment.libraryRoadmapSvcBaseUrl + 'library/';

  public static controlMeasureAddToLibrary = environment.offerBaseURL + '/librarysvc/CounterMeasure';

  public static initialMeasureAddToLibrary = environment.offerBaseURL + '/librarysvc/InitialHazard';

  //get Opportunities
  public static getOpportunitiesDataList = environment.opportunitysvcBaseUrl;

  //get OpportunitiesData
  public static getOpportunitiesIdData = environment.offerBaseURL;

  //get ServiceType
  public static getServiceType = environment.offerBaseURL + '/service/servicematerial';


  //RA APIS LIST
  public static getRoadmapSteps = environment.riskassessmentBaseUrl + 'roadmapsv1';
  public static getServiceRoadmapSteps = environment.riskassessmentBaseUrl;
  public static getRoadmapList = environment.riskassessmentBaseUrl + 'roadmaps';
  public static getRoadmapListWithIndex = environment.riskassessmentBaseUrl;
  public static machineLimits = environment.riskassessmentBaseUrl + 'machinelimits';
  public static serviceRoadmap = environment.riskassessmentBaseUrl;

  public static configureRoadmap = environment.riskassessmentBaseUrl + 'configureroadmapv1';
  public static configureServiceRoadmap = environment.riskassessmentBaseUrl + 'configureserviceroadmap';
  public static createCustomRoadmap = environment.offerLibraryBaseURL + '/customroadmap';
  public static getNewRoadMapDetails = environment.offerLibraryBaseURL + '/CustomTemplateRoadMap';
  public static getCustomTemplateRoadMapData = environment.offerLibraryBaseURL;
  public static getMachineModesList = environment.riskassessmentBaseUrl + 'machinemodes';
  public static upDateMachineModesList = environment.riskassessmentBaseUrl + 'modes';
  public static getLifeCyclePhasesList = environment.riskassessmentBaseUrl + 'lifecyclephases';
  public static getMachineLimits = environment.riskassessmentBaseUrl;
  public static getConfiguredroadmaps = environment.riskassessmentBaseUrl;
  public static getInitialHazardsList = environment.riskassessmentBaseUrl + 'initialhazards';
  public static getCounterMeasuresList = environment.riskassessmentBaseUrl + 'countermeasures';
  public static extendRoadmap = environment.riskassessmentBaseUrl + 'extendroadmapweb';
  public static extendRoadmapv1 = environment.riskassessmentBaseUrl + 'extendroadmapweb/v1';
  public static extendServiceRoadmap = environment.riskassessmentBaseUrl + 'serviceroadmapwebv1';
  public static conflicts = environment.riskassessmentBaseUrl + 'conflicts';
  public static getOverviewLists = environment.riskassessmentBaseUrl;
  public static markAsComplete = environment.riskassessmentBaseUrl + 'markascomplete';
  public static raServiceMarkAsComplete = environment.riskassessmentBaseUrl + 'markascompleteserviceroadmap';
  public static uploadSystemaFile = environment.riskassessmentBaseUrl + 'sistemareport';
  public static machinemedia = environment.riskassessmentBaseUrl + 'machinemedia';
  public static getSystemaData = environment.riskassessmentBaseUrl;


  // User Managment Console
  public static getUsers = environment.userBaseUrl + 'user/users';
  public static deleteUser = environment.userBaseUrl + 'user';
  public static getRoles = environment.userBaseUrl + 'role/roles';
  public static deleteRole = environment.userBaseUrl + 'role';
  public static getRoleAssignments = environment.userBaseUrl + 'userroleassociation/getuserswithrole';
  public static getAssignedUsersInRole = environment.userBaseUrl + 'userroleassociation';
  public static getRbacPermissions = environment.userBaseUrl + 'userpermissionassociation/permissions';
  public static getRbacRolesWithPermissions = environment.userBaseUrl + 'userpermissionassociation/getpermissions';
  public static updateRbacPermissions = environment.userBaseUrl + 'userpermissionassociation/updatepermissions';
  public static addRoleToUsers = environment.userBaseUrl + 'userroleassociation/addroletousers';
  public static revokeRoleFromUser = environment.userBaseUrl + 'userroleassociation/deleteusersfromrole';
  public static getGroupsWithCount = environment.userBaseUrl + 'group/groupwithusercount';
  public static deleteGroup = environment.userBaseUrl + 'group';
  public static getSearchedUserInfoToAdd = environment.userBaseUrl + 'user/adusers';
  public static addNewUsersToAd = environment.userBaseUrl + 'user/addusertoad';
  public static addNewRole = environment.userBaseUrl + 'role';
  public static createGroupAndAddusers = environment.userBaseUrl + 'ugassociation';
  public static revokeGroupFromUser = environment.userBaseUrl + 'ugassociation/deleteusers';

  //Four Eye Quality
  public static getFourEyeQualityPerformInfo = environment.fourEyeQualityBaseUrl;
  public static requestFourEyeQuality = environment.fourEyeQualityBaseUrl + 'foureyesquality';
  public static completeFourEyeQuality = environment.fourEyeQualityBaseUrl + 'complete4eyesquality';
  public static saveEditedHazardInfoInFourEyeQuality = environment.fourEyeQualityBaseUrl + 'hazard';


  //EditOffer
  public static editApproveRejectDeleteOffer = environment.offerBaseURL + '/update';
  // Assign User
  public static assignUserToOffer = environment.offerBaseURL + '/offerassign';
  //Edit Service
  public static editOfferServiceUpdate = environment.offerBaseURL + '/service/edit';
  //getUser
  public static getUser = environment.offerBaseURL + '/getloginusers';

  //getProjectUser
  public static getProjectUser = environment.offerBaseURL + 'getloginusers';

  //AddProjectUser
  public static addProjectUser = environment.projectBaseUrl + 'addstafftoproject';

  //updateProfileData
  public static updateProfileBookmarkData = environment.profileBookmarkData + 'userprofile';

  //Delete AssignUser
  public static AssignUserDelete = environment.projectBaseUrl + 'deletestafffromproject';

  //legislation Document save
  public static uploadlegislationFile = environment.libraryRoadmapSvcBaseUrl + 'legislations';

  //Standard Documents Document save
  public static uploadStandardDocFile = environment.libraryRoadmapSvcBaseUrl + '';

  //getAllLibrariesData
  public static getsavedLibrariesSavedData = environment.libraryRoadmapSvcBaseUrl;

  public static getStaticTemplate = environment.userBaseUrl + 'riskassessmentsvc/GetStaticTemplateDetails';

  //getPredefinedTemplateRoadMap
  public static PredefinedTemplateRoadMap = environment.offerLibraryBaseURL + '/PredefinedTemplateRoadMap';

  //updatePredefinedTemplateRoadMap
  public static updateTemplateRoadmap = environment.offerLibraryBaseURL + '/TemplateRoadmap';

  //updateGroupIDData
  public static updateSiteDetails = environment.offerLibraryBaseURL + '/sitedetails';

  //getGroupIDData
  public static getSiteDetails = environment.offerLibraryBaseURL;

  //deleteTemplateRoadmapSectionsData

  public static deleteTemplateRoadmapSectionsData = environment.offerLibraryBaseURL + '/DeleteTemplateRoadmap';

  public static createMachine = environment.masterMachineSvcBaseUrl + '/MasterMachineFileUploadJson';
  public static getMasterMachineData = environment.masterMachineSvcBaseUrl;
  public static deleteApplicationData = environment.masterMachineSvcBaseUrl + '/deleteapplication';
  public static deleteMachineTypeData = environment.masterMachineSvcBaseUrl + '/deletemachinetype';
  public static deleteControlSystemData = environment.masterMachineSvcBaseUrl + '/deletecontrolsystem';
  public static deleteFeaturesData = environment.masterMachineSvcBaseUrl + '/deletespecificfeature';

  // machineApplicationData post call URL
  public static machineApplicationData = environment.offerBaseURL + '/mastermachinesvc/application';

  // machineControlSystemData post call URL
  public static machineControlSystemData = environment.offerBaseURL + '/mastermachinesvc/controlsystem';

  // machineSpecificFeatureData post call URL
  public static machineSpecificFeatureData = environment.offerBaseURL + '/mastermachinesvc/specificfeature';

  // machineMachineTypeData post call URL
  public static machineMachineTypeData = environment.offerBaseURL + '/mastermachinesvc/machinetype';

  // machineMachineTypeData post call URL
  public static editMachineLevelAttributes = environment.offerBaseURL + '/mastermachinesvc/MachineEdit';

  // saveStaticRATemplate post call URL
  public static saveStaticRATemplate = environment.userBaseUrl + 'riskassessmentsvc/SaveStaticTemplateV1';

  // get header get call URL
  public static getTemplatesData = environment.userBaseUrl + 'riskassessmentsvc/GetStaticTemplateDetailsV1';

  /**
   * Get templates data for quotatiom of api urls
   */
  public static getTemplatesDataForQuotation = environment.userBaseUrl + 'riskassessmentsvc/GetStaticTemplateDetailsV1';

  /**
   * Add update machine images
   */
  public static addUpdateMachineImages = environment.masterMachineSvcBaseUrl + '/AddUpdateMachineImages';

  public static getAllSalesOrganization = environment.customerBaseUrl + '/GetAllSalesOrganization';
  public static getCountry = environment.customerBaseUrl + '/GetCountry';
  public static getAllCustomer = environment.customerBaseUrl + '/GetAllCustomer';
  public static getAllContact = environment.customerBaseUrl + '/GetAllContact';
  public static getCustomerByCompanyId = environment.customerBaseUrl + '/GetCustomerByCompanyId';

  public static addOrUpdateSalesOrganization = environment.customerBaseUrl + '/AddOrUpdateSalesOrganization';
  public static addOrUpdateCustomer = environment.customerBaseUrl + '/AddOrUpdateCustomer';
  public static createContact = environment.customerBaseUrl + '/AddOrUpdateContact';
  public static getMenu = environment.customerBaseUrl + '/menuItems/';
  public static getViewComponentDetails = environment.customerBaseUrl + '/GetViewComponentDetails/';
  public static getAllSalesOrgs =  environment.customerBaseUrl + '/GetAllSalesOrganization';
  public static addUserSaleOrgAssociation = environment.customerBaseUrl + '/UpdateUserSalesOrgAssociation';
  public static getUserSalesOrg =  environment.customerBaseUrl +'/GetUserSalesOrgAssociation/';
  public static getServiceDetails = environment.projectBaseUrl + 'service/';
  public static getAllServiceMaterial = environment.offerBaseURL + '/service/getallservicematerial';
  public static addUpdateServiceMaterial = environment.offerBaseURL + '/service/addupdateservicematerial';
  public static getMachineDetails = environment.offerBaseURL+ '/servicemachines/';
  public static saveTemplate = environment.riskassessmentBaseUrl+ 'SaveStaticTemplateV1';
  public static getAllStepsByRoadmapId = environment.userBaseUrl + 'riskassessmentsvc/GetAllStepsByRoadmapId';
  public static getRoadmapsByServiceMachineId = environment.userBaseUrl + 'riskassessmentsvc/GetRoadmapsByServiceMachineId';
  public static getAllHazardByRoadmapId = environment.userBaseUrl + 'riskassessmentsvc/GetAllHazardByRoadmapId';
  public static uploadImages = environment.riskassessmentBaseUrl + 'UploadImages';
  public static editStaticTemplate = environment.riskassessmentBaseUrl + 'EditStaticTemplate/';
  public static getRoadmapsFromSimilarMachine = environment.riskassessmentBaseUrl + 'getsimilarmachineroadmap';
  public static getProjects = environment.projectBaseUrl + 'projectsv2';
  public static getOffers = environment.offerBaseURL + '/v2';
  public static getOrders = environment.orderBaseUrl + 'ordersv2';
  public static getRoadmapHistory = environment.riskassessmentBaseUrl + 'GetRoadmapHistory';
  public static getRoadmapHistoryForRAWizard = environment.riskassessmentBaseUrl + 'GetRoadmapHistoryForReportWizard';
  public static getDashboard = environment.projectBaseUrl + 'dashboard';
  public static getRecentUpdates = environment.projectBaseUrl + 'recentupdates';
  public static logError = environment.libraryBaseUrl + 'roadmapsvc/logerror';
  public static gethazarddropdowndata = environment.riskassessmentBaseUrl + 'gethazarddropdowndata';
}
