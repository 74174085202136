<ion-content *ngIf="showHazardDetails">
  <div class="save-btn">
    <app-exit-button class="exit-left-margin-120" (click)="goBack()"></app-exit-button>
    <ion-button class="app-btn" shape="round" fill="outline" position="bottom" (click)="handleFormAction()"
      type="submit">
      {{isEditingFourEyeQuality ? 'Save' : 'Complete' | translate}}
    </ion-button>
  </div>
  <div class="hazard-page padding-5">
    <div class="horizontal-stepper">
      <app-stepper [steps]="hazardSteps" (stepDetail)="stepperChange($event, hazardSteps)"
        [isVertical]="false"></app-stepper>
    </div>

  </div>
  <div class="hazard-content">
    <form #addHazardDetails="ngForm" autocomplete="off" (ngSubmit)="complete(addHazardDetails)" novalidate>
      <div [hidden]="selectedStep !== 'hd'">
        <div class="hazard-container">
          <ion-label>{{"Add Hazard Name"| translate}}*</ion-label>
          <ion-item lines=none>
            <ion-input name="hazardName" [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')
          || (this.isRiskReassessment && !this.addNewHazard)" placeholder='{{"Enter Hazard Name"|translate}}'
              [(ngModel)]="hardDetailsData.hazardName" #hazardName="ngModel" required>
            </ion-input>
            <ion-text *ngIf="hazardName.invalid && hazardName.touched" color="danger">Required
            </ion-text>
          </ion-item>
        </div>

        <div ngModelGroup="hazardTypesData">
          <div class="hazard-container">
            <ion-label class="label-css" for="hazardType">{{"Select Hazard Type"|translate}}*</ion-label>
            <ng-select required
            [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')
            || (this.isRiskReassessment && !this.addNewHazard)"
                [items]="sharedValue.hazardDropdowndata"
                bindLabel="hazardTypeName"
                #hazardType="ngModel"
                name="hazardType"
                [(ngModel)]="selectedHazardTypeName"
                (change)="onHazardTypeChange($event)"
                [clearable]="false" [searchable]="false"
                placeholder="{{'Select'|translate}}"
            >
            </ng-select>
            <ion-text *ngIf="hazardType.invalid && hazardType.touched" color="danger">
              Required
            </ion-text >
          </div>
          <div class="hazard-container">
            <ion-label class="label-css" for="source">{{"Select Hazard Source"| translate}}*</ion-label>
            <ng-select required
            [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                [items]="hazardSources"
                bindLabel="source"
                #source="ngModel"
                name="source"
                [(ngModel)]="selectedHazardSourceName"
                [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" [searchable]="false"
                placeholder="{{'Select'|translate}}"
            >
            </ng-select>
            <ion-text *ngIf="source.invalid && source.touched" color="danger">
              Required
            </ion-text >
          </div>
          <div class="hazard-container">
            <ion-label class="label-css" for="consequence">{{"Select Hazard Consequences"| translate}}*</ion-label>
            <ng-select required
            [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                [items]="hazardConsequences"
                bindLabel="consequences"
                #consequence="ngModel"
                name="consequence"
                [(ngModel)]="selectedHazardConsequenceName"
                [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" [searchable]="false"
                placeholder="{{'Select'|translate}}"
            >
            </ng-select>
            <ion-text *ngIf="consequence.invalid && consequence.touched" color="danger">
              Required
            </ion-text>
          </div>
        </div>
      </div>
      <div [hidden]="selectedStep !== 'chr'" class="hazard-container">
        <ion-label class="label-css">{{"Select Machine Mode"| translate}}*</ion-label>
          <ng-select
              [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
              dropdownPosition="bottom" [loading]="getMachineModesLoading" bindLabel="machineMode"
              class="machine-mode cust-name-select" [items]="translatedMachineModeList" [multiple]="true" [selectableGroup]="true"
              [closeOnSelect]="true" [(ngModel)]="hardDetailsData.machineMode" (change)="changeMode($event)"
              #machineMode="ngModel" name="machineMode" placeholder="{{'Select Machine Mode'|translate}}"
              required>
              <ng-template ng-option-tmp let-item="item" let-item$="item$.selected" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}"
                  [ngModel]="item$" />
                {{item.machineMode | translate}}
              </ng-template>
            </ng-select>
            <ion-text *ngIf="machineMode.invalid && machineMode.touched" color="danger">
              Required
            </ion-text>
        <div class="table-container">
          <table class="table" *ngIf="hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0">
            <thead>
              <tr>
                <th>
                  <div class="mode-text"> {{'List'|translate}}</div>
                </th>
                <th *ngFor="let item of this.machineModeLists">
                  <div class="mode-text">{{item}} {{'Weightage'|translate}}</div>
                </th>
              </tr>
            </thead>
            <tbody ngModelGroup="chrnTable">
              <tr *ngFor="let each of hazardRatingList;let i =index" [ngModelGroup]="each.listName">
                <td>{{each.listNameView | translate}}</td>
                <td *ngFor="let item of machineModeLists;let i =index">
                  <ng-select
                    [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                    [items]="each.weightagesList" [clearable]="false" placeholder="{{'Select'|translate}}"
                    class="dropdown" [(ngModel)]="each.dataChrn[item]"
                    (change)="updateIndicativeHRN(each,hazardRatingList,i)" #crntlMaintance="ngModel" [name]="item">
                  </ng-select>
                </td>
              </tr>
              <tr>
                <td>{{ 'Hazard Rating Number (HRN)' | translate}}</td>
                <td *ngFor="let item of chrnTotal;let i =index">
                  <ion-input
                    [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                    readonly [value]="item>=0 ? item : '' "></ion-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="note-label" *ngIf="hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0">
          <small>{{"Hazard_Note" | translate}}- HRN(LO x FE x DPH x NPE)</small>
        </div>
      </div>
      <div [hidden]="selectedStep !== 'hch'" class="initial-hazard-container">
        <div class="hazard-block">
          <div class="initial-hazard-block">
            <ion-card class="hazard-card">
              <ion-card-header class="ion-header-css">
                {{"Initial Hazard"|translate}} 
                <ion-button
                  [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true')|| (this.isRiskReassessment && !this.addNewHazard)"
                  fill="outline" size="small" class="pick-initial-btn" shape="round"
                  (click)="openPickHazardsList('Pick Initial Hazard')" position="bottom">
                  <ion-icon slot="start" name="add-circle-outline">
                  </ion-icon>{{"Pick Initial Hazard" | translate}}
                </ion-button>
              </ion-card-header>

              <ion-card-content>
                <ion-card class="inner-card-content scrollVertical">
                  <ion-textarea
                    [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                    [(ngModel)]="hardDetailsData.pickInitialHazard" #pickInitialHazard="ngModel"
                    name="pickInitialHazard">
                  </ion-textarea>
                </ion-card>
              </ion-card-content>
              <div class="bt-right-container">
                <ion-button class="app-large-btn btn-height" shape="round" fill="outline" color="primary"
                  [disabled]="pickInitialHazardValue==hardDetailsData.pickInitialHazard || !hardDetailsData.pickInitialHazard || (this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                  (click)="initialMeasureAddToLibrary(hardDetailsData.pickInitialHazard)">
                  {{'Add to Library'|translate}}
                </ion-button>
              </div>
            </ion-card>
          </div>
          <div class="control-measure">
            <ion-card class="hazard-card">
              <ion-card-header class="ion-header-css">{{'Control Measure'|translate}}
                <ion-button
                  [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                  fill="outline" size="small" class="pick-initial-btn" shape="round" position="bottom"
                  (click)="openPickHazardsList('Pick Control Measure')">
                  <ion-icon slot="start" name="add-circle-outline">
                  </ion-icon>{{"Pick Control Measure"|translate}}
                </ion-button>
              </ion-card-header>
              <ion-card-content>
                <ion-card class="inner-card-content scrollVertical">
                  <ion-textarea
                    [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false')|| (this.isRiskReassessment && !this.addNewHazard)"
                    [(ngModel)]="hardDetailsData.pickControlMeasure" #pickControlMeasure="ngModel"
                    name="pickControlMeasure">
                  </ion-textarea>
                </ion-card>
              </ion-card-content>
              <div class="plr-right-container">
                <ion-button class="app-large-btn btn-height" shape="round" fill="outline" color="primary"
                  [disabled]="(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                  (click)="determinePlr()">
                  {{'Determined PLR'|translate}}
                </ion-button>
                <ion-button class="app-large-btn btn-height" shape="round" fill="outline" color="primary"
                  [disabled]="pickCntrlMeasureValue==hardDetailsData.pickControlMeasure || !hardDetailsData.pickControlMeasure ||(this.roadmapStatus ==='Completed' && this.isCustom === 'true') || (this.isRiskReassessment && !this.addNewHazard)"
                  (click)="controlMeasureAddToLibrary(hardDetailsData.pickControlMeasure)">
                  {{'Add to Library'|translate}}
                </ion-button>
              </div>

              <div class="plr-text">
                <div>
                  <ion-label>
                    {{ 'Determined PLR' | translate}} <ion-icon name="information-circle-outline"></ion-icon>
                  </ion-label>
                  <ion-input class="plr-input" readonly name="determinePlrValue"
                    [(ngModel)]="hardDetailsData.determinePlrValue" #determinePlrValue="ngModel"></ion-input>
                </div>
                <div>
                  <ion-label> {{'Recommended Category'|translate}} <small>(Optional)</small>
                    <ion-icon name="information-circle-outline"></ion-icon>
                  </ion-label>
                  <ion-input class="category-input" readonly name="determineCategoryValue"
                    [(ngModel)]="recommendedCategoryValue">
                  </ion-input>
                </div>
              </div>
              <div class="determined-plr-block">
                <ion-checkbox
                  [disabled]="(this.roadmapStatus==='Completed' && this.isCustom==='true')|| (this.isRiskReassessment && !this.addNewHazard)"
                  [(ngModel)]="isIncludeCategoryChecked" mode="md" [ngModelOptions]="{standalone: true}"
                  (ionChange)="addCategoryValue()"></ion-checkbox>
                <ion-label class="plr-label">{{"Include Recommended Category"|translate}}
                </ion-label>
              </div>
            </ion-card>
          </div>
        </div>
      </div>
      <div [hidden]="selectedStep !== 'hrn'" class="hazard-container">
        <div *ngIf="hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0">
          <ion-label class="label-css">{{"Machine Mode"| translate}}</ion-label>
          <div class="machine-mode-text">
            <span *ngFor="let item of this.machineModeLists;let i=index">
              {{item}}{{i === machineModeLists.length-1 ? '':','}}
            </span>
          </div>
        </div>
      <div class="table-container"> 
          <table class="table" *ngIf="hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0">
            <thead>
              <tr>
                <th>
                  <div class="mode-text"> {{'List'|translate}}</div>
                </th>
                <th *ngFor="let item of this.machineModeLists">
                  <div class="mode-text">{{item}} {{'Weightage'|translate}}</div>
                </th>
              </tr>
            </thead>
            <tbody ngModelGroup="ihrnTable">
              <tr *ngFor="let each of hazardRatingList;let i =index" [ngModelGroup]="each.listName">
                <td>{{each.listNameView | translate}}</td>
                <td *ngFor="let item of machineModeLists;let i =index">
                  <ng-select
                    [readonly]="(this.roadmapStatus ==='Completed' && this.isCustom === 'false') || (this.isRiskReassessment && !this.addNewHazard)"
                    [items]="each.weightagesList" [clearable]="false" placeholder="{{'Select'|translate}}"
                    class="dropdown" [(ngModel)]="each.dataIhrn[item]" #ihrnCalculation="ngModel" [name]="item">
                  </ng-select>
                </td>
              </tr>
              <tr>
                <td>{{ 'Hazard Rating Number (HRN)' | translate}}</td>
                <td *ngFor="let item of ihrnTotal;let i =index">
                  <ion-input
                    [ngClass]="[item <=5 ? 'rating-green' : item <= 50 ? 'rating-yellow' :item <=500 ?'rating-orange':item <=1000 ?'rating-red':'rating-red']"
                    readonly [value]="item>=0 ? item : '' "></ion-input>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="no-ihrn-label" *ngIf="!(hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0)">
            Choose Current Hazard Rating Number (HRN) to calculate Indicative Hazard Rating Number (IHRN)
          </div>
        </div>
        <div class="note-label" *ngIf="hardDetailsData.machineMode && hardDetailsData.machineMode.length > 0">
          <small>{{"Hazard_Note" | translate}}- HRN(LO x FE x DPH x NPE)</small>
        </div>
        
      </div>
      <div [hidden]="selectedStep !== 'hi'">
        <div class="file-upload-container-wrapper">
          <!-- File Input Selector UI -->
          <div class="file-upload-container">
            <label for="myHazardFileInput" class="file-upload-label">
              <ion-icon slot="start" name="add-circle-outline" class="upload-icon"></ion-icon>
              <input type="file" multiple accept="image/*" id="myHazardFileInput" #hazardImage
                (change)="inputHazardImg($event.target.files, hazardImage)" />
            </label>
          </div>

          <!-- Preview of Selected Images -->
          <div class="preview-images-container">
            <div *ngIf="hazardImagesRes.length > 0" class="server-image-preview">
              <div *ngFor="let item of hazardImagesRes; let i = index;" class="image-preview-wrapper">
                <div [ngStyle]="{ 'background-image': 'url(' + item.url + ')' }" class="card-transition local-image-card">
                </div>
                <span class="delete-hazard-img">
                  <ion-icon name="close-circle-outline" (click)="deleteImageConfirmation(item, i)" tooltip="{{ 'Remove' | translate }}"
                    placement="bottom"></ion-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="footer-btn">
      <ion-button class="app-btn next-btn" *ngIf="selectedStep !== 'hd'" shape="round" fill="outline" color="primary"
        (click)="changeHazardStep('back', selectedStep)">
        {{'Back'|translate}}
      </ion-button>
      <ion-button class="app-btn next-btn" *ngIf="selectedStep !== 'hi'" shape="round" fill="outline" color="primary"
        (click)="changeHazardStep('next', selectedStep)">
        {{'Next'|translate}}
      </ion-button>
    </div>
  </div>
</ion-content>