<div class="header-container">
  <div class="left-column">
    <span>{{'Add Hazard Details' | translate}}</span>
  </div>
  <div class="right-column">
    <ion-button class="app-large-btn float-right margin-top-12" shape="round" fill="outline" color="primary"
      (click)="openAddHazard()">
      {{ 'New Hazard' | translate }}
    </ion-button>
    <img tooltip="{{'Close' | translate }}" placement="bottom" src="/assets/icon/cancel.svg" class="close-icon" alt=""
      (click)="close()">
  </div>
</div>

<div class="body-container scrollVertical">
  <div *ngIf="hazardData.length === 0" class="no-hazard-available">
    <span class="section-title">{{'No Hazards Available' | translate}}</span>
  </div>
  <div *ngFor="let section of hazardData" class="section-container">
    <span class="section-title">{{'Section' | translate}}: {{section.sectionName}}</span>
    <span class="sub-section-title">{{'Subsection' | translate}}: {{section.subSectionName}}</span>
    <span class="step-title">{{'Steps' | translate}}: {{section.stepBody}}</span>
    <div class="hazard-container">
      <input type="checkbox" #copyHazard id="copyHazard" title="checkbox" 
        [(ngModel)]="section.isChecked"
        (change)="updateHazard(section, $event.target.checked)" class="hazard-checkbox">
      <label for="copyHazard" class="step-title">
        {{section.hazardName}}
      </label>
    </div>
  </div>
</div>
<div class="add-hazard-btn">
  <ion-button class="app-large-btn float-right margin-top-12"
    [disabled]="hazardData.length === 0" shape="round" fill="outline" color="primary"
    (click)="addCopiedHazard()">
    {{ 'Add' | translate }}
  </ion-button>
</div>