import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ShareValuesService } from '../../../../services/sharedValues/share-values.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendCallService } from '../../../../services/backend-call/backend-call.service';
import { ToastService } from 'src/app/services/toast.service';
import { SubSection } from '../../service-roadmaps/roadmap-interface';
import { AppConstants } from 'src/app/utilities/constant';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { addUniqueEntry,isNotEmptyArray } from '../../../../utilities/utils';
import { UserAuthService } from 'src/app/services/user-auth/user-auth.service';
import { ConfirmationModalComponent } from 'src/app/components/confirmation-modal/confirmation-modal.component';
import { GraphService } from 'src/app/services/graph/graph.service';
import { EventProvider } from 'src/app/services/event';
import { SubSink } from 'subsink';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { StatusPopoverComponent } from 'src/app/components/status-popover/status-popover.component';
import { CopyRoadmapComponent } from '../copy-roadmap/copy-roadmap.component';
interface RoadmapSection {
  title: string;
  content: string;
}
interface RoadmapSectionDetails {
  createdDate: string;
  id: string;
  sectionId: string;
  sectionName: string;
  SubSections?: SubSection[]
}
@Component({
  selector: 'roadmap-view-create',
  templateUrl: './roadmap-view-create.component.html',
  styleUrls: ['./roadmap-view-create.component.scss'],
})
export class RoadmapViewCreateComponent implements OnInit,OnChanges {
public sections = [];
public selectedSection: RoadmapSection;
public selectedIndex: number = 0;
@Input() fromLibrary : boolean = false;
@Input() roadmapName : string;
@Input() roadmapData: any = {};
@Input() roadmapId: string;
@Input() serviceMachineId : string = '';
@Input() project_id : string = '';
@Input() isCustom: boolean;
@Input() isMachineBased: boolean;
@Output() addHazardDetail = new EventEmitter<boolean>();
@Output() editHazardDetails = new EventEmitter<boolean>();
selectedLanguage: string = this.serviceMulti.getDefaultLanguage();
public accordionOpen = false;
public question: any;
public notes: any;
public compliance: any;
public subSections = [];
public sectionId : number;
public roadmapStatus: number;
public isRiskReassessment: boolean;
@Output() exitEvent = new EventEmitter<boolean>();
public reviewImageSrc: string | ArrayBuffer;
public previewMachineImages: any[]=[];
public imageFiles: File[];
public currentIndex = 0;
public hazardImages = [];
complianceOptions = [
  { value: 'yesCompliant', label: 'Yes, Compliant' },
  { value: 'notCompliant', label: 'Not Compliant' },
  { value: 'notApplicable', label: 'Not applicable' }
];
hazardRatingList = [
  { listName: 'Probability of Occurrence', listNameView: this.selectedLanguage == 'en' ? 'Likelihood Of Occurences(LO)' : 'Eintrittswahrscheinlichkeit (LO)', val: 'PO', weightagesList: this.sharedValue.weightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Frequency of Exposition', listNameView: this.selectedLanguage == 'en' ? 'Frequency Of Exposure(FE)' : 'Häufigkeit der Gefährdung(FE)', val: 'FE', weightagesList: this.sharedValue.FEweightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Degree of Severity', listNameView: this.selectedLanguage == 'en' ? 'Degree Of Possible Harm(DPH)' : 'Schweregrad der Verletzung (DPH)', val: 'SD', weightagesList: this.sharedValue.DPEweightagesList, dataChrn: {}, dataIhrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, rating: '' },
  { listName: 'Number of Persons Exposed', listNameView: this.selectedLanguage == 'en' ? 'Number of Persons at Risk(NPE)' : 'Anzahl der gefährdeten Personen (NPE)', val: 'NP', weightagesList: this.sharedValue.NPEweightagesList, dataChrn: {}, dataRiskChrn: {}, dataRiskIhrn: {}, dataIhrn: {}, rating: '' }];
private userInfo: {
  name: string; userId: string; email: string; jobTitle: string; isGlobalAdmin: boolean; profileData: {}; permissions: any[]; // this.initailizeFroala();
}; 
imageSrc: any;
queryParams: any={};
machineImagesList = [];
hazardImagesList = [];
public isSaveDisabled = true;
public subs = new SubSink();
public hasEmptySelectedValue: boolean;
public copiedHazard: any;
// public isNewHazardAdded = false;
constructor(
  private modalController: ModalController,
  public sharedValue: ShareValuesService,
  public translate: TranslateService,
  public backendService: BackendCallService,
  private toastService: ToastService,
  private serviceMulti: LanguageSupportService,
  public userAuthService: UserAuthService,
  private graphService: GraphService,
  private route: ActivatedRoute,
  public sharedService: ShareValuesService,
  private eventProvider: EventProvider,
  private confirmationService: ConfirmationService,
  public popoverController: PopoverController,
  private changeDetectorRef: ChangeDetectorRef

) {
  this.route.queryParams.subscribe(async (params) => {
    this.queryParams=params;
  });
  setTimeout(() => {
    this.isSectionCompleted();
  }, 800);
}

ngOnChanges(changes: SimpleChanges): void {
  if(typeof this.roadmapData !== 'undefined')
  {  
    this.sections = this.roadmapData?.section ? this.roadmapData?.section : [];
    this.sections?.forEach(section => {       
    section.subSections?.forEach(subSection => {    
      subSection.subSectionId = subSection.subSection_Id;      
      subSection.steps.forEach(step => {
        step.accordionOpen = false;
        step.stepId = step.id;
        if(typeof step.answers !== 'undefined')
        {
          step.answers.forEach(element => {
            step.compliant = element.yesComplaint;
            step.notCompliant = element.notCompliant;
            step.hazard = element.hazard;
            step.notApplicable = element.notApplicable; 
            
            if(element.hazardDetails?.length > 0)
            {
              element.hazardDetails.forEach(hazard => {
                step.existingHazardDetails = [];
                step.existingHazardDetails.push({hazardName : hazard.name, id : hazard.id});
              });              
            }
            step.hazardDetails = { hazards:[] };
            
            this.setRadioButtonSelection(element);
          });
        }
        else
        {
            step.compliant = false;
            step.notCompliant = false;
            step.hazard = false;
            step.notApplicable = false; 
            step.answers = [];
            step.existingHazardDetails = []; 
            step.hazardDetails = { hazards:[] };
        }
        });
      });
    });
    this.selectSection(0);
    this.isSectionCompleted();
  }
}

public async showRoadmapGrid(){
  const isConfirmed = await this.confirmationService.presentConfirmationModal(AppConstants.ARE_YOU_SURE_TO_EXIT, AppConstants.EXIT);
  if (isConfirmed) {
      this.exitEvent.emit(true);
  }
}

ngOnInit() {
  this.sharedService.getHazardDropdownData();
  this.userInfo = this.userAuthService.getUserList();
  if (this.isMachineBased) {
    this.complianceOptions.push({ value: 'hazard', label: 'Report Hazard' })
  }
}
/**
 * Deletes hazard localy
 * 
 * @param data 
 * @param hazardId 
 */
private deleteHazardLocally(data, hazardId) {
    data.forEach(subsection => {
      subsection.steps.forEach(step => {
        step.answers.forEach(answer => {
          if (Array.isArray(answer.hazardDetails)) {
            answer.hazardDetails = answer.hazardDetails.filter(hazard => hazard.id !== hazardId);
          }
        });
      });
    });
    this.subSections = data;
  }
/**
 * Deletes roadmap confirmation
 * 
 * @param hazard 
 */
  public deleteHazard(hazard, i) {
    const payload = {
      modifiedBy: this.userAuthService.getUserList().userId,
      machineHazardId: hazard.id,
      hazardName: hazard.name,
    }

    this.backendService.deleteHazard(payload).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.deleteHazardLocally(this.subSections, hazard.id);
        this.toastService.presentToast('Hazard Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }
    }, err => {
      this.toastService.presentToast('Something went wrong', 4000, AppConstants.ERROR_SNACKBAR, 'top');
    });
  }

  /**
   * Deletes hazard confirmation
   * 
   * @param hazard 
   */
  async deleteHazardConfirmation(hazard, i){
    const isConfirmed = await this.confirmationService.presentConfirmationModal(AppConstants.ARE_YOU_SURE_TO_DELETE, AppConstants.DELETE);
    if (isConfirmed) {
      this.deleteHazard(hazard, i);
    }
  }

public changeRoadmapName(ev) {
  this.isSaveDisabled = false;
}

public addSection() {
  const newSection: any = {
    sectionId: '',
    sectionName: '',
    subSections: [],
    hasEmptySelectedValue: true
  };
  this.isSaveDisabled = false
  this.sections.push(newSection);
  this.subSections = this.sections[this.sections.length-1].subSections;
  this.selectSection(this.sections.length - 1);
}

/**
 * Deletes section
 * 
 * @param section section
 * @param index index
 * @param event event
 */
public deleteSection(section, index: number, event: Event) {
  event.stopPropagation();
  this.selectedSection = null;
  this.selectedIndex = null;
  let sectionId: any;
  if(section.sectionId){
    this.sections.forEach((element, i) => {
      if (element.sectionId == section.sectionId) {
        sectionId = element.sectionId;
        this.sections.splice(index, 1);
      }
    });
    if(this.fromLibrary)
    {
      const payload = {
        type: 'section',
        id: section.sectionId
      };
      this.backendService.deleteTemplateRoadmap(payload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.toastService.presentToast('Section Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            this.selectSection(index- 1);  
          } else {
            this.toastService.presentToast('Failed to Delete Section', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }          
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
    else
    {
    const payload = {
      id: this.roadmapId,
      serviceMachineId: this.serviceMachineId,
      projectId: this.project_id,
      section: [{
        sectionId: sectionId,
        isDelete: true,
        subSection: [{
          subSection_Id: '',
          isDelete: false,
          steps: [{
            id: '',
            isDelete: false
          }]
        }]
      }]
    };
    this.backendService.deleteRoadmapSectionsData(payload).subscribe(
      (data: any) => {
        this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      },
      err => {
        this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );
   }
  }else {
    this.sections.splice(index, 1);
  }

}

/**
 * Selects section
 * 
 * @param index 
 */
public selectSection(index: number) {
  this.selectedIndex = index;
  this.selectedSection = this.sections?.[index] ?  this.sections?.[index] : null;
  this.subSections = this.sections?.[index]?.subSections;
}

onSectionNameChanged(this){}

onSubSectionNameChanged(this){}

onNotesChanged(this){}

onStepBodyChanged(this){}

onRadioSelectionChanged(event: any,step) {
  const selectedValue = event.detail.value;
  let selectedOption = this.complianceOptions.find(option => option.value === selectedValue).label;
  step.compliant = false;
  step.notCompliant = false;
  step.notApplicable = false;    
  step.hazard = false;    
  if (selectedOption == 'Yes, Compliant') { 
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-primary', selectedValue: 'yesCompliant'});
    } else {
      step.answers[0].selectColorClass = 'btn-primary';
      step.answers[0].selectedValue = 'yesCompliant';
    }
    step.compliant = true;
    step.answers[0].notApplicable = false;
  } else if(selectedOption == 'Not applicable') {
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-primary', selectedValue: 'notApplicable'});
    } else {
      step.answers[0].selectColorClass = 'btn-primary';
      step.answers[0].selectedValue = 'notApplicable';
    }
    step.notApplicable = true;
    step.answers[0].notApplicable = true;
  } else if(selectedOption =='Report Hazard') {
    this.isSaveDisabled = false;
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-danger', selectedValue: 'hazard'});
    } else {
      step.answers[0].selectColorClass = 'btn-danger';
      step.answers[0].selectedValue = 'hazard';
    }
    step.hazard = true;
    step.answers[0].notApplicable = false;
  } else  if (selectedOption == 'Not Compliant') {
    if (step?.stepNote==null || step?.stepNote == '') {
      this.isSaveDisabled = true;
    } else {
      this.isSaveDisabled = false;
    }
    if (step?.answers?.length === 0) {
      step?.answers?.push({selectColorClass: 'btn-default', selectedValue: 'notCompliant'});
    } else {
      step.answers[0].selectColorClass = 'btn-default';
      step.answers[0].selectedValue = 'notCompliant';
    }
    step.notCompliant = true;
    step.answers[0].notApplicable = false;
  }
  this.addAreAllMarkedAsNotApplicableFlag()
}
showHazardDetails: boolean;
addHazardDetails(index, step,subSection,section) 
{
  if (step.stepBody) {
    const data = { sectionName: section.sectionName, subSectionName: subSection.subSectionName, index, roadMapName: this.roadmapName, question: step.stepBody };
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'merge',        
    };
    const hazardInfo = {
      sectionData: data,
      hazardData: step,
      isCustom:this.isCustom,
      roadmapStatus: this.roadmapStatus,
      isRiskReassessment: this.isRiskReassessment,
      addNewHazard: true,
      serviceMachineId:this.serviceMachineId
    };
    // Store the parameter in sessionStorage
    sessionStorage.setItem('hazardInfo', JSON.stringify(hazardInfo));
    this.showHazardDetails=true;
    this.eventProvider.BroadcastEvent('edit-hazard', false);
    //this.router.navigate([window.location.pathname, 'add-hazard-details'],navigationExtras);
  } else {
    this.toastService.presentToast('Please complete all the mandatory Fields', 4000, AppConstants.ERROR_SNACKBAR, 'top');
  }

}
/**
 * Copies hazard
 * 
 * @param index 
 * @param step 
 * @param subSection 
 * @param selectedSection 
 */
public async copyHazard(index, step, subSection, selectedSection) {
    const props = { roadmapSections: this.roadmapData?.section };
    const modal = await this.modalController.create({
      component: CopyRoadmapComponent,
      cssClass: 'medium-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props
    });
    await modal.present();
    await modal.onDidDismiss().then((result: any) => {
      if (result.data.dataType != undefined && result.data.dataType === AppConstants.ADD_HAZARD) {
        this.addHazardDetails(index, step, subSection, selectedSection)
      } else if (result.data.dataType != undefined && result.data.dataType === AppConstants.COPY_HAZARD) {
        this.copiedHazard = result.data.copiedHazard;
        result.data.copiedHazard.forEach((element) => {
          element.id = '';
          const payload = {
            id: '',
            hazardName: element?.name,
            initialHazard: element?.initial_hazard,
            countermeasure: element?.counter_measure,
            determinedPLR: element.plrObj?.determinedPLR,
            category: element.plrObj?.category,
            hazardTypes: [{
              type: '',
              sources: [{
                hazardTypeId: '',
                hazardSourceId: '',
                source: ''
              }],
              consequences: [{
                hazardTypeId: '',
                hazardConsequenceId: '',
                consequence: ''
              }]
            }],
            hazardRatingNumber: {
              machineModes: [],
              hrNs: []
            },
            files: [{
              fileName: element.hazardMedia[0]?.fileName,
              contentType: element.hazardMedia[0]?.type,
              url: element.hazardMedia[0]?.url,
              image_Size: element.hazardMedia[0]?.image_Size
            }],
            plrObj: element.plrObj
          };

          element.hazardType.forEach((hazard) => {
            const hazardSources = hazard.hazardSources.map((source) => ({
              hazardTypeId: hazard.hazardTypeId,
              hazardSourceId: source.id,
              source: source.source,
            }));

            const hazardConsequences = hazard.hazardConsequences.map((consequence) => ({
              hazardTypeId: hazard.hazardTypeId,
              hazardConsequenceId: consequence.id,
              consequence: consequence.consequences,
            }));

            payload.hazardTypes.push({
              type: hazard.hazardTypeName,
              sources: hazardSources,
              consequences: hazardConsequences,
            });
          });

          element.hrnCalculation.forEach((hrn) => {
            payload.hazardRatingNumber.hrNs.push({
              hrnType: hrn.type,
              version: hrn.version,
              value: [{
                mode: hrn.mode,
                po: hrn.po,
                sd: hrn.sd,
                fe: hrn.fe,
                np: hrn.np,
                rating: hrn.rating
              }]
            });
          });

          // Add the payload to step.hazardDetails.hazards for saving copied hazard
          if (step?.hazardDetails?.hasOwnProperty('hazards')) {
            step.hazardDetails.hazards.push(payload);
          } else {
            step.hazardDetails = {
              hazards: [payload]
            };
          }

          // Add the payload to step.answers[0].hazardDetails to show copied hazard on UI
          if (step.answers[0]?.hazardDetails) {
            step.answers[0].hazardDetails.push(payload);
          } else {
            step.answers[0].hazardDetails = [payload];
          }
        });

        this.isSaveDisabled = false;
      }
    });
  }

  /**
   * Closes roadmap view create component
   */
  public close(){
      this.modalController.dismiss();
  }

  /**
   * To enable save button on changing note text
   * @param ev 
   * @param step 
   */
  public onNoteChange(ev, step) {
    if(ev?.detail?.value && step?.notCompliant == true) {
      this.isSaveDisabled = false;
      step.stepNote = ev?.detail?.value;           
    }
    else if(step?.notCompliant == true && (step.stepNote == null || step.stepNote == ''))
    {
      this.isSaveDisabled = true;
    }
}

setRadioButtonSelection(element) {
  if (element.yesComplaint) {
    element.selectedValue = 'yesCompliant';
    element.selectColorClass = 'btn-primary';
  } else if (element.notApplicable) {
    element.selectedValue = 'notApplicable';
    element.selectColorClass = 'btn-primary';
  } else if (element.hazard) {
    element.selectedValue = 'hazard';
    element.selectColorClass = 'btn-danger'
  } else if (element.notCompliant) {
    element.selectedValue = 'notCompliant';
    element.selectColorClass = 'btn-default';
  } else {
    element.selectedValue = '';
    element.selectColorClass = ''
  } 

}


hazardCheckLists: any;
  editHazard(eachHazard, step) {
    if (eachHazard.id) {
      this.backendService.getFourEyeQualityHazardCheckListsInfo(eachHazard.id).then((res: any) => {
        if (res) {
          this.hazardCheckLists = res;
          this.constructHazardHrnStructure(eachHazard, step);
          setTimeout(() => {
            this.eventProvider.BroadcastEvent('edit-hazard', true);
          }, 400);
        }
      }).catch(err => {
        console.log('getting issue in fetching four eye quality hazard checklist info :', err);
      });
    }
  }

async constructHazardHrnStructure(eachHazard, step) {
  if (isNotEmptyArray(this.hazardCheckLists.hrnCalculations)) {
    const machineModes = [];
    const machineModesWithIds = [];
    const riskmachineModes = [];
    const riskMachineModesWithIds = [];
    this.hazardCheckLists?.hrnCalculations.forEach(_hrn => {
      if (_hrn.type == 'Current') {
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(machineModes, _val.mode));}
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(machineModesWithIds, { mode: _val.mode, id: _val.id, type: _hrn.type }));}
      }
      if (_hrn.type == 'Risk Reassessment Current') {
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(riskmachineModes, _val.mode));}
        if (isNotEmptyArray(_hrn.values)) {_hrn.values.forEach(_val => addUniqueEntry(riskMachineModesWithIds, { mode: _val.mode, id: _val.id, type: _hrn.type }));}
      }
    });
    this.hazardCheckLists.hrnCalculations.forEach(_hrn => {
      if (_hrn.type == 'Current' || _hrn.type == 'current') {
        this.hazardRatingList.forEach(_hr => {
          machineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };
                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }


                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po;
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataChrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Indicative' || _hrn.type == 'indicative') {
        this.hazardRatingList.forEach(_hr => {
          machineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataIhrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Risk Reassessment Current') {
        this.hazardRatingList.forEach(_hr => {
          riskmachineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataRiskChrn[_mode] = body;
              }
            });
          });
        });
      } else if (_hrn.type == 'Risk Reassessment Indicative') {
        this.hazardRatingList.forEach(_hr => {
          riskmachineModes.forEach(_mode => {
            _hrn.values.forEach(_val => {
              if (_mode == _val.mode) {
                const rating = _val.rating;//+_val.po * +_val.sd * +_val.fe * +_val.np;
                const body = {
                  label: '',
                  value: '',
                  id: '',
                  rating
                };

                if (_hr.val == 'PO') {
                  if (_val.po == '1') {
                    body.value = '0.033';
                  }
                  else if (_val.po == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.po == '3') {
                    body.value = '1';
                  }
                  else if (_val.po == '4') {
                    body.value = '2';
                  }
                  else if (_val.po == '5') {
                    body.value = '5';
                  }
                  else if (_val.po == '6') {
                    body.value = '8';
                  }
                  else if (_val.po == '7') {
                    body.value = '10';
                  }
                  else if (_val.po == '8') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'SD') {
                  if (_val.sd == '1') {
                    body.value = '0.1';
                  }
                  else if (_val.sd == '2') {
                    body.value = '0.5';
                  }
                  else if (_val.sd == '3') {
                    body.value = '1';
                  }
                  else if (_val.sd == '4') {
                    body.value = '2';
                  }
                  else if (_val.sd == '5') {
                    body.value = '4';
                  }
                  else if (_val.sd == '6') {
                    body.value = '8';
                  }
                  else if (_val.sd == '7') {
                    body.value = '15';
                  }
                }
                else if (_hr.val == 'FE') {
                  if (_val.fe == '1') { body.value = '0.1'; }
                  else if (_val.fe == '2') { body.value = '0.2'; }
                  else if (_val.fe == '3') { body.value = '1'; }
                  else if (_val.fe == '4') { body.value = '1.5'; }
                  else if (_val.fe == '5') { body.value = '2.5'; }
                  else if (_val.fe == '6') { body.value = '4'; }
                  else if (_val.fe == '7') { body.value = '15'; }
                }
                else if (_hr.val == 'NP') {
                  if (_val.np == '1') { body.value = '1'; }
                  else if (_val.np == '2') { body.value = '2'; }
                  else if (_val.np == '3') { body.value = '4'; }
                  else if (_val.np == '4') { body.value = '8'; }
                  else if (_val.np == '5') { body.value = '12'; }
                }

                if (_hr.val == 'PO') {body.id = _val.po;}
                else if (_hr.val == 'SD') {body.id = _val.sd;}
                else if (_hr.val == 'FE') {body.id = _val.fe;}
                else if (_hr.val == 'NP') {body.id = _val.np;}

                // if (_hr.val == 'PO') body.value = _val.po
                // else if (_hr.val == 'SD') body.value = _val.sd
                // else if (_hr.val == 'FE') body.value = _val.fe
                // else if (_hr.val == 'NP') body.value = _val.np

                const matchedWeight = _hr.weightagesList.find(_wght => _wght.value == body.value);
                if (matchedWeight) {body.label = matchedWeight.label;}
                _hr.dataRiskIhrn[_mode] = body;
              }
            });
          });
        });
      }
    });
    this.hazardCheckLists.machineModes = machineModes;
    this.hazardCheckLists.machineModesWithIds = machineModesWithIds;
    this.hazardCheckLists.riskmachineModes = riskmachineModes;
    this.hazardCheckLists.riskMachineModesWithIds = riskMachineModesWithIds;
    await this.goToHazrdsPage(eachHazard, step);
  } else {
    this.hazardCheckLists.machineModes = [];
    this.hazardCheckLists.machineModesWithIds = [];
    this.hazardCheckLists.riskmachineModes = [];
    this.hazardCheckLists.riskMachineModesWithIds = [];
    await this.goToHazrdsPage(eachHazard, step);
  }
}

goToHazrdsPage(eachHazard, step) {
  step.hazardTypesData = [];
  const sectionInfo = {
    sectionName: step.sectionName,
    subSectionName: step.subSectionName,
    index: 0,
    roadMapName: this.roadmapName
  };
  const hazrdInfo = {
    id: eachHazard.id,
    stepBody: '',
    stepNote: '',
    answers: null,
    hazardCount: 0,
    isListItemOpened: true,
    compliance: 'hazard',
    hazardName: this.hazardCheckLists?.name,
    hazardType: this.hazardCheckLists?.hazardType,
    hazardRatingList: this.hazardRatingList,
    machineModes: this.hazardCheckLists?.machineModes,
    machineModesWithIds: this.hazardCheckLists?.machineModesWithIds,
    riskmachineModes: this.hazardCheckLists?.riskmachineModes,
    riskMachineModesWithIds: this.hazardCheckLists?.riskMachineModesWithIds,
    pickInitialHazard: this.hazardCheckLists?.initial_hazard,
    pickInitialHazardValue: this.hazardCheckLists?.initial_hazard,
    pickControlMeasure: this.hazardCheckLists?.counter_measure,
    pickCntrlMeasureValue: this.hazardCheckLists?.counter_measure,
    determinePlrValue: this.hazardCheckLists?.plr,
    recommendedCategory: this.hazardCheckLists?.category,
    hazardImagesList: this.hazardCheckLists?.hazardMedia,
    plrObj: this.hazardCheckLists.plrObj
  };
  this.hazardCheckLists.hazardType.forEach(_ht => {
    const sourceBody = [];
    const consequeceBody = [];
    _ht.hazardSources.forEach(_hts => { sourceBody.push(_hts.source); });
    _ht.hazardConsequences.forEach(_hts => { consequeceBody.push(_hts.consequences); });
    step.hazardTypesData.push({ hazardType: _ht.hazardTypeName || _ht.type, source: sourceBody, consequences: consequeceBody });
  });
  const navigationExtras: NavigationExtras = {
    queryParamsHandling: 'merge',
  };
  const hazardInfo = {
    isEditingFourEyeQuality: true,
    sectionData: sectionInfo,
    isCustom:this.isCustom,
    hazardData: hazrdInfo,
    roadmapStatus: this.roadmapStatus,
    isRiskReassessment: this.isRiskReassessment,
    addNewHazard: false
  };
  // Store the parameter in sessionStorage
  sessionStorage.setItem('hazardInfo', JSON.stringify(hazardInfo));
  //this.router.navigate([window.location.pathname, 'add-hazard-details'],navigationExtras);
  this.showHazardDetails=true;
}


fnGetOptionName(value)
{
  let optionname = '';
  if(value != '')
    optionname = this.complianceOptions.find(it=>it.value == value)?.label == "Report Hazard" ? "Hazard Reported" : this.complianceOptions.find(it=>it.value == value)?.label;
  return optionname;
}

/**
 * Toggles accordion
 * 
 * @param step 
 * @param stepIndex 
 */
public toggleAccordion(step, stepIndex) {
  // Loop through all subsections
  this.subSections.forEach((subSection) => {
    // Check if the current subsection contains the clicked step
    if (subSection.steps.includes(step)) {
      // Loop through all steps in the current subsection
      subSection.steps.forEach((stp, i) => {
        // Toggle the clicked accordion and close all others
        stp.accordionOpen = i === stepIndex ? !stp.accordionOpen : false;
      });
    }
  });
}

/**
* Deletes subsection
* 
* @param subsection 
* @param index 
*/
public deleteSubsection(subsection, index: number) {
  let subSectionId: any;
  if(subsection.subSection_Id)  {
    this.subSections.forEach((element, i) => {
      if (element.subSection_Id == subsection.subSection_Id) {
        subSectionId = element.subSection_Id;
        this.subSections.splice(index, 1);
        return;
      }
    });
    if(this.fromLibrary)
    {
      const payload = {
        type: 'subsection',
        id: subsection.subSection_Id
      };
      this.backendService.deleteTemplateRoadmap(payload).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.toastService.presentToast('SubSection Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
            } else {
            this.toastService.presentToast('Failed to Delete SubSection', 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }          
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
    else
    {
      const payload = {
        id: this.roadmapId,
        serviceMachineId: this.serviceMachineId,
        projectId: this.project_id,
        section: [{
          sectionId: this.sectionId,
          isDelete: false,
          subSection: [{
            subSection_Id: subSectionId,
            isDelete: true,
            steps: [{
              id: '',
              isDelete: false
            }]
          }]
        }]
      };
      this.backendService.deleteRoadmapSectionsData(payload).subscribe(
        (data: any) => {
          this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
        },
        err => {
          this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
        }
      );
    }
  }else {
    this.subSections.splice(index, 1);
  }

}

  fnExitHazardDetails(_event) {
    //this.sections = _event;
    // console.log('Back from Add Hazard', this.sections);
    this.showHazardDetails = false;
    // this.eventProvider.event('complete-hazard').subscribe((res)=>{
    //   console.log(res,'res');
    //   this.onSave();
    // })
  }

/**
* Deletes step
* 
* @param step 
* @param index 
* @param event 
*/
public deleteStep(step, index: number, event: Event) {
  event.stopPropagation();
  let subSectionId: any;
  if(step.id)  {
    this.subSections.forEach(element => {
      subSectionId = element.subSection_Id;
      element.steps.forEach((eachStep, i) => {
        if (step.id === eachStep.id) {
          element.steps.splice(i, 1);
        }
      });
    });
    if(this.fromLibrary)
    {
        const payload = {
          type: 'step',
          id: step.stepId
        };
        this.backendService.deleteTemplateRoadmap(payload).subscribe(
          (data: any) => {
            if (data.statusCode == 200) {
              this.toastService.presentToast('Step Deleted Successfully', 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
              } else {
              this.toastService.presentToast('Failed to Delete Step', 4000, AppConstants.ERROR_SNACKBAR, 'top');
            }            
          },
          err => {
            this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
          }
        );
    }
    else
    {
    const payload = {
      id: this.roadmapId,
      serviceMachineId: this.serviceMachineId,
      projectId: this.project_id,
      section: [
        {
          sectionId: this.sectionId,
          isDelete: false,
          subSection: [
            {
              subSection_Id: subSectionId,
              isDelete: false,
              steps: [
                {
                  id: step.id,
                  isDelete: true
                }
              ]
            }
          ]
        }
      ]
    };
    this.backendService.deleteRoadmapSectionsData(payload).subscribe(
      (data: any) => {
        this.toastService.presentToast(data, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      },
      (err) => {
        this.toastService.presentToast(err.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    );
   }
  }else {
    this.subSections.forEach((element, i) => {
      element.steps.splice(index, 1)
    });
  }
}

/**
 * Adds new section
 */
public addNewSection() {
  const newSubSection = {
    subSection_Id: '',
    subSectionName: `Sub Section ${this.subSections.length + 1}`,
    steps: []
  };
  this.isSaveDisabled = false
  this.subSections.push(newSubSection);
}

/**
 * Adds new step
 * 
 * @param subSectionIndex 
 */
public addNewStep(subSectionIndex: number,subSection) {
  const newStep = {
    id: '',
    stepBody: `New Step ${this.subSections[subSectionIndex].steps.length + 1}`,
    stepNote: `New Step ${this.subSections[subSectionIndex].steps.length + 1} note`,
    padLock: false,
    createdDate: new Date().toISOString(),
    answers : [{ selectedValue: '' ,selectColorClass: ''}],
    hazardCount: 0,
    accordionOpen: false
  };
  this.isSaveDisabled = false
  this.subSections[subSectionIndex].steps.push(newStep);
  this.isSectionCompleted();
}

/**
 * Gets answer
 * 
 * @param step 
 * @returns selectedValue
 */
getAnswer(step: any) {
  if (!step.answers) {
    step.answers = [{}]; // Initialize answers as an array with a default object
  }
  return step.answers[0]?.selectedValue;
}


onSave()
{
  let payload: any = {    
    roadmapId: this.roadmapId,
    project_Id: this.project_id,
    roadmapName: this.roadmapName,
    serviceMachineId: this.serviceMachineId || sessionStorage.getItem('selectedServiceIdNonSAP'),
    userId: this.userInfo.userId,
    isCustom: this.isCustom,
    maskAsComplete: false    
  };

  if(this.fromLibrary)
   payload['section'] = this.sections;
  else
   payload['sections'] =this.sections;

   //To remove temporary added hazard
   payload.sections.map((section)=>{
    section.subSections.map((subSec)=>{
      subSec.steps.map((step)=>{
        step.answers.map((answer)=>{
          if (Array.isArray(answer.hazardDetails)) {
            answer.hazardDetails = answer.hazardDetails.filter((hazard) => hazard.id !== '');
          }
        })
      })
    })
   })
   const shouldStop = this.sections.some(section =>
    section.subSections.some(subSection =>
      subSection.steps.some(step =>
        step?.notCompliant === true && (!step?.stepNote || step?.stepNote === '')
      )
    )
  );
 
  if (shouldStop) {
    this.toastService.presentToast("Please fill in the notes for Not Compliant steps", 4000, AppConstants.ERROR_SNACKBAR, 'top');
    return;
  } 
  else
  {
  if(this.fromLibrary)
  {
    this.backendService.updateTemplateRoadmap(payload).subscribe(async (res: any) => {
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      this.exitEvent.emit(true);
    }), err => {
      console.log("Machine roadmap err", err)
    }
  }
  else if(this.isMachineBased) {
    this.backendService.extendRoadmapv1(payload).subscribe(async (res: any) => {
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      // Commented until find solution for edit unsaved hazard
      // if(this.isNewHazardAdded){
        this.exitEvent.emit(true);
      // }else {
      //   this.viewRoadmap(payload)
      // }
    }), err => {
      console.log("Machine roadmap err", err)
    }
  } else if(!this.isMachineBased) {
    this.backendService.extendServiceRoadmap(payload).subscribe(async (res: any) => {
      if ((res.statusCode === 200)) {     
        this.toastService.presentToast(res.message, 4000, AppConstants.SUCCESS_SNACKBAR, 'top');
      }else{
        this.toastService.presentToast(res.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
      // Commented until find solution for edit unsaved hazard
      // if(this.isNewHazardAdded){
        this.exitEvent.emit(true);
      // }else {
      //   this.viewRoadmap(payload)
      // }
    }), err => {
      console.log("Service roadmap err", err)
    }
  }
 }
}
// Commented until find solution for edit unsaved hazard
// viewRoadmap(roadmap){
//   this.backendService.getRoadmapSteps(roadmap.roadmapId, this.userAuthService.getUserList().userId,
//   roadmap.serviceMachineId, roadmap.project_Id).subscribe((data: any) => {
//           if (data) {
//            this.sections = [];
//            this.roadmapData = data.response;
//            this.sections = this.roadmapData?.section;
//            this.sections?.forEach(section => {       
//             section.subSections?.forEach(subSection => {    
//               subSection.subSectionId = subSection.subSection_Id;      
//               subSection.steps.forEach(step => {
//                 step.accordionOpen = false;
//                 step.stepId = step.id;
//                 if(typeof step.answers !== 'undefined')
//                 {
//                   step.answers.forEach(element => {
//                     step.compliant = element.yesComplaint;
//                     step.notCompliant = element.notCompliant;
//                     step.hazard = element.hazard;
//                     step.notApplicable = element.notApplicable; 
                    
//                     if(element.hazardDetails?.length > 0)
//                     {
//                       element.hazardDetails.forEach(hazard => {
//                         step.existingHazardDetails = [];
//                         step.existingHazardDetails.push({hazardName : hazard.name, id : hazard.id});
//                       });              
//                     }
//                     step.hazardDetails = { hazards:[] };
                    
//                     this.setRadioButtonSelection(element);
//                   });
//                 }
//                 else
//                 {
//                     step.compliant = false;
//                     step.notCompliant = false;
//                     step.hazard = false;
//                     step.notApplicable = false; 
//                     step.answers = [];
//                     step.existingHazardDetails = []; 
//                     step.hazardDetails = { hazards:[] };
//                 }
//                 });
//               });
//             });
//             this.cdr.detectChanges();
//            // this.eventProvider.BroadcastEvent('set-section', true);
//            // this.returnSection(this.sections)
//            // console.log('firing event');
//            console.log(this.sections, ';this.sections');
           
//          }
//       }, err => { 
//     });
// }



  /**
   * Deletes conformation
   * 
   * @param [data] data
   * @param [index] index
   * @param [event] event
   * @param [itemType] itemType
   */
  public async deleteConfirmation(data?, index?: number, event?: Event, itemType?: string) {
    const title = AppConstants.ARE_YOU_SURE_TO_DELETE;
    const actionText = AppConstants.DELETE;
    const props = {
      confirmationTitle: title,
      actionText
    };
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isActionConfirmed } } = await modal.onWillDismiss();
    if (isActionConfirmed) {
      if (itemType == 'section-kebab') {
        this.deleteSection(data, index, event);
      } else if (itemType == 'subsection-kebab') {
        this.deleteSubsection(data, index);
      } else if (itemType == 'step-kebab') {
        this.deleteStep(data, index, event);
      }     

    }
  }


/**
 * Shows slide
 *
 * @param [index]
 */
  public showSlide(index: number = this.previewMachineImages?.length - 1): void {
    const carouselInner = document.querySelector('.ra-carousel-inner') as HTMLElement;
    const itemWidth = document.querySelector('.ra-carousel-item')?.clientWidth || 0;
    const newPosition = -index * itemWidth;
    carouselInner.style.transform = `translateX(${newPosition}px)`;
    this.currentIndex = index;
  }

  /**
   * Next slide
   */
  public nextSlide(): void {
    const totalItems = this.previewMachineImages.length;
    this.currentIndex = (this.currentIndex + 1) % totalItems;
    this.showSlide(this.currentIndex);
  }

  /**
   * Prev slide
   */
  public prevSlide(): void {
    const totalItems = this.previewMachineImages.length;
    this.currentIndex = (this.currentIndex - 1 + totalItems) % totalItems;
    this.showSlide(this.currentIndex);
  }

  /**
   * Uploads hazard images
   */
  public async uploadHazardImages() {
    this.previewMachineImages = [];
    const file_path = `${this.queryParams.project_Id}/${this.queryParams.serviceId}/${this.queryParams.machineId}/${this.roadmapId}/`;
    const formData = new FormData();
    const hazardImageFormData = this.sharedValue.prepareImageFormData(formData, this.imageFiles, file_path)
    await this.backendService.uploadImages(hazardImageFormData).subscribe((res: any) => {
      res.forEach(element => {
        const imageData = {
          fileName: element?.file_name,
          contentType: element?.content_type,
          url: element?.url,
          image_Size: element?.file_size
        };
        this.hazardImagesList.push(imageData);
        this.previewMachineImages.push(imageData);
      })
    }, (err) => {
    });
  }

  /**
   * Inputs machine img
   * 
   * @param files 
   * @param machineImage 
   */
  public inputMachineImg(files: File[], machineImage: HTMLInputElement) {
    const self = this;
    this.imageFiles = Array.from(files);
    for (const file of files) {
      const reader = new FileReader();
      const fileSize = file.size;
      const imgData = {
        fileName: file.name,
        fileSize: file.size,
        fileReaderResult: reader.result,
        fileData: file
      };
      reader.onload = e => {
        self.reviewImageSrc = reader.result;
        imgData.fileReaderResult = reader.result;
        if (fileSize < 4194304) {
          self.previewMachineImages.push(imgData);
        } else {
          self.previewMachineImages.push(imgData);
          this.sharedValue.errorShowToast("", "Image size exceeds 4MB")
        }
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {

      };
    }
    this.uploadHazardImages();
    machineImage.value = '';
  }

  public async onClickKebab(data, index, ev, type, subsection?) {
    this.addAreAllMarkedAsNotApplicableFlag()
    const props = { type, roadmapViewData: data };
    const popover = await this.popoverController.create({
      component: StatusPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: props,
      showBackdrop: false,
    });
    await popover.present();
    await popover.onDidDismiss().then((result) => {
      if (result.role == 'delete') {
        this.deleteConfirmation(data, index, ev, type)
      } else if (result.role == 'markNotApplicable') {
        this.confirmMarkAsNotApplicable(type, data, subsection?.id)
      }
    });
  }

  public async confirmMarkAsNotApplicable(type, data, parentId) {
    const title = type == 'section-kebab' ? AppConstants.ARE_YOU_SURE_TO_MARK_SECTION_NA : type == 'subsection-kebab' ? AppConstants.ARE_YOU_SURE_TO_MARK_SUBSECTION_NA : AppConstants.ARE_YOU_SURE_TO_MARK_STEP_NA;
    const actionText = AppConstants.YES;
    const props = {
      confirmationTitle: title,
      actionText
    };
    const modal = await this.modalController.create({
      component: ConfirmationModalComponent,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const { data: { isActionConfirmed } } = await modal.onWillDismiss();
    if (isActionConfirmed) {
      this.markNotApplicable(type, data, parentId)
    }
  }

  public markNotApplicable(
    type: 'section-kebab' | 'subsection-kebab' | 'step-kebab',
    data,
    parentId
  ): void {
    const updateStepAndAnswers = (step) => {
      step.notApplicable = true;
      step.compliant = step.notCompliant = step.hazard = false;
      step.answers.forEach(answer => {
        Object.assign(answer, {
            notApplicable: true,
            yesComplaint: false,
            notCompliant: false,
            hazard: false,
            selectedValue: 'notApplicable',
            selectColorClass: 'btn-primary'
        });
      });
    };

    this.sections?.forEach(section => {
      if (type === 'section-kebab' && section.sectionId === data?.sectionId) {
        section?.subSections?.forEach(subsection =>
            subsection?.steps?.forEach(updateStepAndAnswers)
        );
      } else if (type === 'subsection-kebab' && section.sectionId === data?.id) {
        section?.subSections
          ?.filter(subsection => subsection?.subSection_Id === data?.subSection_Id)
          .forEach(subsection => subsection?.steps?.forEach(updateStepAndAnswers));
      } else if (type === 'step-kebab' && section.sectionId === parentId) {
        section?.subSections
          ?.filter(subsection => subsection?.subSection_Id === data?.subSection_Id)
          .forEach(subsection =>
            subsection?.steps
              ?.filter(step => step?.stepId === data?.stepId)
              .forEach(updateStepAndAnswers)
          );
      }
    });
    this.isSaveDisabled = false
    this.changeDetectorRef.detectChanges();
  }

  public addAreAllMarkedAsNotApplicableFlag(): void {
    this.sections?.forEach(section => {
      // Check if all steps in all subsections are marked as not applicable
      section.areAllMarkedAsNotApplicable = section.subSections.every(subsection =>
        subsection.steps.every(step =>
          step.answers.every(answer => answer.notApplicable)
        )
      );
      section.subSections.forEach(subsection => {
        // Check if all steps in the subsection are marked as not applicable
        subsection.areAllMarkedAsNotApplicable = subsection.steps.every(step =>
          step.answers.every(answer => answer.notApplicable)
        );
        subsection.steps.forEach(step => {
          // Check if all answers in the step are marked as not applicable
          step.areAllMarkedAsNotApplicable = step.answers.every(answer => answer.notApplicable);
        });
      });
    });
    this.isSectionCompleted();
  }

 /**
  * Determines whether section is completed
  */
 private isSectionCompleted() {
    this.sections?.forEach(section => {
      // Default the section state to true (all answers complete)
      section.hasEmptySelectedValue = false;
  
      section.subSections.forEach(subsection => {
        subsection.steps.forEach(step => {
          // Check for empty selectedValue in answers
          if (step.answers && step.answers.length > 0) {
            step.answers.forEach(answer => {
              if (!answer.selectedValue || answer.selectedValue.trim() === '') {
                section.hasEmptySelectedValue = true; // Mark section as incomplete
              }
            });
          }else if(step.answers.length === 0){
            section.hasEmptySelectedValue = true;
          }
        });
      });
    });
  }
}
