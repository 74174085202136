import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  isObjectEmpty,
} from 'src/app/utilities/utils';
import { ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { AlertModalPage } from 'src/app/components/alert-modal/alert-modal.page';
import * as moment from 'moment';
import { UserAuthService } from '../user-auth/user-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendCallService } from '../backend-call/backend-call.service';
import { LanguageSupportService } from 'src/app/services/language-support.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GraphService } from '../graph/graph.service';
import { AppConstants } from 'src/app/utilities/constant';
@Injectable({
  providedIn: 'root',
})
export class ShareValuesService {
  globalSearchInput: string;
  expanded = false;
  public showExitOnEditorTemplate = true;
  public isGridDataLoading = true;
  extendedRoadMapPayload: any = {};
  listOfServices: any = [];
  finalNotification = [];
  customersInfo = new BehaviorSubject(null);
  eachSelectedContactDetails: any;
  offerDetailsContact_id: string;
  isFourEyeQualityCheckRequested = false;
  selectedRoadmapsList = [];
  selectedServiceRoadmapsList = [];
  selectedOpporData: any;
  private roadMapStatusLists = [];
  viewOfferListOfServices: any = [];
  // PLR Values
  sValue = '';
  fValue = '';
  pValue = '';
  // Country Values
  public countryData: any;
  public contactType: any;
  public countryList: any;
  // groupsArray = [];
  searchedProjectLists = [];
  public dashboardCustomizationValueSubmitted: BehaviorSubject<{}> =
    new BehaviorSubject<{}>({
      isValueChanged: true,
    });

  weightagesList = [
    { label: 'Almost Impossible (0.033)', value: '0.033', id: '1' },
    { label: 'Highly Unlikely (0.5)', value: '0.5', id: '2' },
    { label: 'Unlikely,but could occur (1)', value: '1', id: '3' },
    { label: 'Possible, but unusual (2)', value: '2', id: '4' },
    { label: 'Even Chance,could happen (5)', value: '5', id: '5' },
    { label: 'Possible,not surprised (8)', value: '8', id: '6' },
    { label: 'Likely, to be expected (10)', value: '10', id: '7' },
    { label: 'Certain (15)', value: '15', id: '8' }
  ];

  weightagesListGermanLng = [
    { label: 'Nahezu unmöglich (0,033)', value: '0.033', id: '1' },
    { label: 'Sehr unwahrscheinlich (0,5)', value: '0.5', id: '2' },
    { label: 'Unwahrscheinlich, könnte aber vorkommen (1)', value: '1', id: '3' },
    { label: 'Möglich, aber ungewöhnlich (2)', value: '2', id: '4' },
    { label: 'Vielleicht - kann passieren (5)', value: '5', id: '5' },
    { label: 'Wahrscheinlich, nicht überraschend (8)', value: '8', id: '6' },
    { label: 'Wahrscheinlich, zu erwarten (10)', value: '10', id: '7' },
    { label: 'Sicher (15)', value: '15', id: '8' }
  ];

  FEweightagesList: any[] = [
    { label: 'Infrequently (0.1)', value: '0.1', id: '1' },
    { label: 'Annually (0.2)', value: '0.2', id: '2' },
    { label: 'Monthly (1)', value: '1', id: '3' },
    { label: 'Weekly (1.5)', value: '1.5', id: '4' },
    { label: 'Daily (2.5)', value: '2.5', id: '5' },
    { label: 'Hourly (4)', value: '4', id: '6' },
    { label: 'Constantly (5)', value: '5', id: '7' }
  ];

  FEweightagesGermanLng: any[] = [
    { label: 'Unregelmäßig (0,1)', value: '0.1', id: '1' },
    { label: 'Jährlich (0.2)', value: '0.2', id: '2' },
    { label: 'Monatlich (1)', value: '1', id: '3' },
    { label: 'Wöchentlich (1,5)', value: '1.5', id: '4' },
    { label: 'Täglich (2.5)', value: '2.5', id: '5' },
    { label: 'Stündlich (4)', value: '4', id: '6' },
    { label: 'Permanent (5)', value: '5', id: '7' }
  ];


  DPEweightagesList: any[] = [
    { label: 'Scratch/Bruise (0.1)', value: '0.1', id: '1' },
    { label: 'Leceration/Mild III Health (0.5)', value: '0.5', id: '2' },
    { label: 'Break Minor Bone Or Minor Illness (Temporary) (1)', value: '1', id: '3' },
    { label: 'Break Major Bone Or Minor Illness (Permanent) (2)', value: '2', id: '4' },
    { label: 'Loss of 1 Limb/eye Or Serious Illness (Temporary) (4)', value: '4', id: '5' },
    { label: 'Loss of 2 Limbs Or Serious Illness (Permanent) (8)', value: '8', id: '6' },
    { label: 'Fatality (15)', value: '15', id: '7' }
  ];

  DPEweightagesListGermanLng: any[] = [
    { label: 'Kratzer/Prellung (0.1)', value: '0.1', id: '1' },
    { label: 'Platzwunde / Leichte Krankheit (0,5)', value: '0.5', id: '2' },
    { label: 'Bruch eines kleinen Knochens (1)', value: '1', id: '3' },
    { label: 'Bruch eines großen Knochens (2)', value: '2', id: '4' },
    { label: 'Verlust von einem Gliedmaß/Auge (4)', value: '4', id: '5' },
    { label: 'Verlust von zwei Gliedmaßen (8)', value: '8', id: '6' },
    { label: 'Tod (15)', value: '15', id: '7' }
  ];

  NPEweightagesList: any[] = [
    { label: '1-2 (1)', value: '1', id: '1' },
    { label: '3-7 (2)', value: '2', id: '2' },
    { label: '8-15 (4)', value: '4', id: '3' },
    { label: '16-50 (8)', value: '8', id: '4' },
    { label: '50+ (12)', value: '12', id: '5' }
  ];
  fedExStatusStepperData = [
    { name: 'Opportunity Created', isdone: true },
    { name: 'Quotation Created', isdone: false },
    { name: 'Quotation Approved', isdone: false },
    { name: 'Quotation Sent', isdone: false },
    { name: 'PO Received', isdone: false },
    { name: 'Order Released', isdone: false },
    { name: 'Order Planning', isdone: false },
    { name: 'Order Execution', isdone: false },
    { name: 'Quality Check', isdone: false },
    { name: 'RA Report  Generated', isdone: false }
  ];
  selectedMachineName: string;
  erp_project_id: any;
  customRaodmapsList = [];
  seviceCustomRoadmapsList = [];
  editHazardPayload: any;//{id: '', name: ''}
  erpOfferId: string;
  // Following 3 variables will be used to edit machine via create offer flow
  createOfferIsAnyMachineSelected = false;
  createOfferSelectedMachines: any = [];
  createOfferSelectedMachineIds: any = [];
  selectedLanguage: string = this.languageSupportService.getDefaultLanguage();
  hazardDropdowndata: any[] = [];
  constructor(private graphService: GraphService, private http: HttpClient, public backendService: BackendCallService, public translate: TranslateService, public toastController: ToastController, public modalController: ModalController, public userAuth: UserAuthService,
    private languageSupportService: LanguageSupportService,
    private toastService: ToastService
  ) {
    this.finalNotification = JSON.parse(localStorage.getItem('notificationvalue')) == null ? [] : JSON.parse(localStorage.getItem('notificationvalue'));
  }

  /**
   * converting chart months data
   *
   * @param originalData
   * @param projectLegends
   *
   * @returns
   */
  transformChartsMonthsData(originalData: any,projectLegends): any[] {
    const keys= Object.keys(originalData.result);
    const transformedData: any[] = [];
    transformedData.push(projectLegends);
    const weekCount: string[] = [];

    // Extracting all unique keys (dayOfWeek) from the original data
    for (const key in originalData.result) {
      originalData.result[key].forEach((item: any) => {
        if (!weekCount.includes(item.weekCount)) {
          weekCount.push(item.weekCount);
        }
      });
    }

    // Creating rows for each key and populating with respective data or 0 if not found
    weekCount.forEach(eachWeek => {
      const rowData = [eachWeek];
      keys.forEach(key => {
        const data = originalData.result[key].find((item: any) => item.weekCount === eachWeek);
        rowData.push(data ? data.data : 0);
      });
      transformedData.push(rowData);
    });

    return transformedData;
  }

  /**
   * converting chart weeks data
   *
   * @param originalData
   * @param projectLegends
   * @returns
   */
  transformChartsWeeksData(originalData: any,projectLegends): any[] {
   const keys= Object.keys(originalData.result);
    const transformedData: any[] = [];
    transformedData.push(projectLegends);
    const daysOfWeek: string[] = [];

    // Extracting all unique keys (dayOfWeek) from the original data
    for (const key in originalData.result) {
      originalData.result[key].forEach((item: any) => {
        if (!daysOfWeek.includes(item.dayOfWeek)) {
          daysOfWeek.push(item.dayOfWeek);
        }
      });
    }

    // Creating rows for each key and populating with respective data or 0 if not found
    daysOfWeek.forEach(day => {
      const rowData = [day];
      keys.forEach(key => {
        const data = originalData.result[key].find((item: any) => item.dayOfWeek === day);
        rowData.push(data ? data.data : 0);
      });
      transformedData.push(rowData);
    });

    return transformedData;
  }

   /**
    * converting chart Days data
    *
    * @param originalData
    * @param projectLegends
    * @returns
    */
   transformChartsDayData(originalData: any,projectLegends): any[] {
    const keys= Object.keys(originalData.result);
     const transformedData: any[] = [];
     transformedData.push(projectLegends);
     const hours: string[] = [];

     // Extracting all unique keys (Hours) from the original data
     for (const key in originalData.result) {
       originalData.result[key].forEach((item: any) => {
         if (!hours.includes(item.hours)) {
          hours.push(item.hours+1);
         }
       });
     }

     // Creating rows for each key and populating with respective data or 0 if not found
     hours.forEach(hour => {
       const rowData = [hour];
       keys.forEach(key => {
         const data = originalData.result[key].find((item: any) => item.hours === hour);
         rowData.push(data ? data.data : 0);
       });
       transformedData.push(rowData);
     });

     return transformedData;
   }

  updateDashboardCustomizationSubject(data): void {
    this.dashboardCustomizationValueSubmitted.next(data);
  }

  intiazureSearch(searchInput, fullSearch: boolean) {
    let data;
    if (!fullSearch) {
      data = {
        search: searchInput//this.globalSearchInput//this.searchInput
      };
    } else {
      data = {
        queryType: 'full',
        search: searchInput,
        searchMode: 'all'
      };
    }
    this.searchedProjectLists = [];
    this.backendService.azureSearchresults(data).subscribe(
      (response: any) => {
        const lists = [];
        const searchedProject = response.value;
        searchedProject.forEach(_serv => {
          const body = {
            machineName: '',//_mch.machine_name,
            machineType: '',//_mch.machine_type,
            product_id: '',//_srv.product_id,
            customerName: _serv.customer_name,
            projectId: _serv.erp_id,
            no_of_services: _serv?.no_of_services,
            projectManager: _serv.project_manager,
            tempproject_Id: _serv.project_Id
          };
          lists.push(body);
        });
        this.searchedProjectLists = lists;
      });
  }

  getDashboardCustomizationSubjectData(): Observable<{}> {
    return this.dashboardCustomizationValueSubmitted;
  }

  setRoadMapStatusLists(lists = []) {
    this.roadMapStatusLists = lists;
  }

  getRoadMapStatusLists() {
    return this.roadMapStatusLists;
  }

  setServiceRoadMapStatusLists(lists = []) {
    this.roadMapStatusLists = lists;
  }

  getServiceRoadMapStatusLists() {
    return this.roadMapStatusLists;
  }

  initializaCreateBucketList(bucketName, bucketId, projectId, orderId, machineLists = [], serviceId?) {
    const body = {
      bucket_Name: bucketName,
      bucket_Id: bucketId,
      project_Id: projectId,
      order_Id: orderId,
      service_Id: serviceId,
      machine_Id: []
    };
    if (!bucketName) {delete (body.bucket_Name);}
    if (!bucketId) {delete (body.bucket_Id);}
    if (isNotEmptyArray(machineLists)) {body.machine_Id = machineLists;}
    if (!serviceId) {delete body.service_Id;}
    return body;
  }

  initializeAddMachineToExistingBucket(bucketid, machine_ids = []) {
    const body = {
      id: '',
      bucketId: bucketid,
      machineIds: []
    };
    if (isNotEmptyArray(machine_ids)) {
      machine_ids.forEach(_mid => {
        body.machineIds.push(_mid);
      });
    }
    return body;
  }


  /**
   * Create Teams site in Micrsoft SharePoint
   *
   * @param siteData
   */
  async updateSiteDetails(siteData) {
    const data = {
      id: '',
      sitename: siteData.displayName,
      siteid: siteData.id,
      itemid: '',
      driveid: '',
      project_id: '',
      erp_project_id: '',
      created_at: new Date().toISOString(),
      created_by: '',
      modified_at: new Date().toISOString(),
      modified_by: ''
    };
    await this.backendService.updateSiteDetails(data).subscribe((res) => {
    }, err => {
    });
  }

  /**
   * Checking Teams site created in Micrsoft SharePoint
   *
   * @param erp_project_id
   * @param company_code
   */
  checkTeamsSiteExistOrNot(erp_project_id: string, company_code: string) {
    if (erp_project_id && erp_project_id != null && company_code) {
      this.backendService.getSiteDetails(erp_project_id + '_' + company_code).subscribe((res: any) => {
        if (res.length == 0) {
          this.graphService.getGroups().subscribe(async (res: any) => {
            let isExistsTeams: any = {};
            isExistsTeams = res.value.find(element => element.displayName === erp_project_id + '_' + company_code);
            if (isExistsTeams) {
              await this.updateSiteDetails(isExistsTeams);
              this.updateSiteId(res, erp_project_id, company_code);
            } else {
              const data = {
                'template@odata.bind': 'https://graph.microsoft.com/v1.0/teamsTemplates(\'standard\')',
                displayName: erp_project_id + '_' + company_code,
                description: erp_project_id + '_' + company_code
              };
              this.graphService.createTeam(data).subscribe((res) => {
                this.graphService.getGroups().subscribe(async (res: any) => {
                  let isExistsTeams: any = {};
                  isExistsTeams = res.value.find(element => element.displayName === erp_project_id + '_' + company_code);
                  if (isExistsTeams) {
                    await this.updateSiteDetails(isExistsTeams);
                    this.updateSiteId(res, erp_project_id, company_code);
                  }
                });
              }, err => {
              });

            }
          });
        } else {
          this.updateSiteId(res, erp_project_id, company_code);
          let isExistsTeams: any = {};
          isExistsTeams = res.find(element => element.sitename === erp_project_id + '_' + company_code);

          // Commented for now
          // if (isExistsTeams && res) {
          //   this.graphService.getUSerByUserPrincipleName().pipe(
          //     switchMap((userRes) => {
          //       if (userRes.id) {
          //         const payload = {
          //           "@odata.id": `https://graph.microsoft.com/v1.0/users/${userRes.id}`
          //         };
          //         return this.graphService.addOwnerToGroup(isExistsTeams.siteid, payload);
          //       }
          //       return of(null); // Return an observable to prevent breaking the chain
          //     }),
          //     catchError((err) => {
          //       console.error('Error:', err); // Handle errors or log them
          //       return of(null); // Return an observable to prevent breaking the chain
          //     })
          //   ).subscribe((addOwnerRes) => {
          //     // Handle the response after adding owner to the group
          //   });
          // }

        }
      }, err => {
        console.log('Error', err);
      });
    }

  }

  async updateSiteId(data, erp_project_id, company_code) {
    if (data) {
      this.graphService.groupId = '';
      this.graphService.siteId = '';
      const siteInfo = data.find(element => element.sitename === erp_project_id + '_' + company_code);
      if (siteInfo && siteInfo.sitename === erp_project_id + '_' + company_code) {
        this.graphService.groupId = siteInfo.siteid;
        try {
          const siteData: any = await this.graphService.getSiteId().toPromise();
          this.graphService.siteId = siteData.parentReference.siteId;
        } catch (error) {
          console.error('Error fetching site ID:', error);
        }
      }
    }
  }



  initialiizeEditBucketInfo(bucketLists, project_Id, order_Id, service_Id, user_Id?, machine_Id = []) {
    const bucket = [];
    if (isNotEmptyArray(bucketLists)) {
      bucketLists.forEach(_bl => {
        const body = {
          bucket_Id: _bl.bucketId,
          bucket_Name: _bl.newTitle || _bl.title,
          project_Id,
          order_Id,
          service_Id
        };
        if (user_Id) {body['user_Id'] = user_Id;}
        bucket.push(body);
      });
    }
    return bucket;
  }

  initializeDeleteBucketInfo(bucketLists) {
    const bucket = [];
    if (isNotEmptyArray(bucketLists)) {
      bucketLists.forEach(_bl => {
        bucket.push(_bl.Bucket_Id);
      });
    }
    return bucket;
  }

  convertToUtcFormat(date) {
    return moment.utc(moment(date)).format();
  }

  initializeCreateProject(projectInfo, customerInfo) {
    const body = {
      project_Id: null,
      erp_project_Id: null,
      project_Name: projectInfo.name,
      description: projectInfo.textDescription,
      Start_Date: projectInfo.date,
      end_Date: projectInfo.endDate,
      status: 'Initiated',
      controlling_Area_Code: customerInfo.controlling_area,
      customer_Id: customerInfo.customer_id,
      //company_Code: projectInfo.company_Code,//customerInfo.company_Code,
      company_Code: projectInfo.company_Code.company_code,
      customer_Name: projectInfo.company_Code.customer_name,
      assignedStaff: [],
      customerDetails: {
        customer_Id: customerInfo.customer_id,
        customer_Name: customerInfo.customer_name,
        erp_Customer_Id: customerInfo.erp_customer_id,
        country_Code: customerInfo.country_code,
        city: customerInfo.city,
        zipcode: customerInfo.zipcode,
        street: customerInfo.street,
        phone_Number: customerInfo.phone_number,
        company_Name: customerInfo.company_name,
        controlling_Area_Code: customerInfo.controlling_area_code,
        language: customerInfo.language,
        contacts: customerInfo.contacts
      },
    };
    if (
      isNotNullAndUndefined(customerInfo.assignedStaff) &&
      !isObjectEmpty(customerInfo.assignedStaff)
    ) {
      const _asPayLoad = {
        first_Name: customerInfo.assignedStaff.first_Name,
        last_Name: customerInfo.assignedStaff.last_Name,
        display_Name: customerInfo.assignedStaff.display_Name,
        login_Name: customerInfo.assignedStaff,
        email_Address: customerInfo.assignedStaff.login_Name,
        user_Role: customerInfo.assignedStaff.user_Role,
      };
      body.assignedStaff.push(_asPayLoad);
    }
    return body;
  }

  initializeEditProject(projectId, projectInfo, customerInfo) {
    const body = {
      project_Id: projectId,
      project_Name: projectInfo.projectName,
      project_Description: projectInfo.projectDesc,
      end_Date: projectInfo.endDate,
      start_Date: projectInfo.startDate,
      company_Code: projectInfo.company_Code,
      contacts: [
        {
          contact_Id: customerInfo.selectedContactList.contact_Id,
          erp_Contact_Id: customerInfo.selectedContactList.erp_Contact_Id,
          first_Name: customerInfo.selectedContactList.first_Name,
          last_Name: customerInfo.selectedContactList.last_Name,
          display_Name: customerInfo.selectedContactList.display_Name,
          email_Address: customerInfo.selectedContactList.email_Address,
          title: customerInfo.selectedContactList.title,
          language: customerInfo.selectedContactList.language,
          phone_Number: customerInfo.selectedContactList.phone_Number,
          department: customerInfo.selectedContactList.department
        }
      ]
    };
    return body;
  }

  mapServicesListInfo(list) {
    const body = {
      offerId: list.service.erp_Wbs_Id,
      projectId: list.project.project_Id,
      contactPerson: '',
      companyName: list.project.comapny_Name,
      customerEmail: '',
      customerName: list.project.customerDetails?.customer_Name,
      customerPhoneNumber: '',
      //customerPhoneNumber: list.project.customerDetails?.phone_Number,
      customerAddress: `${list.project.customerDetails?.street},${list.project.customerDetails?.zipcode},${list.project.customerDetails?.city}`,
      customerId: list.project.customerDetails?.customer_Id,
      serviceType: list.service.service_Type,
      serviceLocation: list.service.service_Location,
      serviceStartDate: list.service.start_Date,
      serviceEndDate: list.service.end_Date,
      region: list.service.service_Region,
      serviceDescription: list.service.service_Description,
    };
    if (isNotEmptyArray(list.project.customerDetails?.contacts)) {
      const contactInfo = list.project.customerDetails?.contacts[0];
      body.contactPerson = (contactInfo.first_Name && contactInfo.last_Name) ? contactInfo.first_Name + contactInfo.last_Name : '';
      body.customerEmail = contactInfo.email_Address;
      body.customerPhoneNumber = contactInfo.phone_Number;
    }
    return body;
  }

  initialzeNewUsersToAdd(userLists) {
    const users = [];
    if (isNotEmptyArray(userLists)) {
      userLists.forEach(_ul => {
        const body = {
          id: _ul.Id,
          first_Name: _ul.First_Name,
          last_Name: _ul.Last_Name,
          login_Name: _ul.Login_Name,
          email_Address: _ul.Email_Address
        };
        users.push(body);
      });
    }
    return users;
  }

  initalizeRoleAssignments(roleId, users) {
    const roleAssignmentsInfo = {
      roleId: '',
      users: []
    };
    if (roleId) {roleAssignmentsInfo.roleId = roleId;}
    if (isNotEmptyArray(users)) {
      users.forEach(_us => {
        const body = {
          id: _us.Id,
          first_Name: _us.First_Name,
          last_Name: _us.Last_Name,
          login_Name: _us.Login_Name,
          email_Address: _us.Email_Address
        };
        if (_us.display_Name) {body['display_Name'] = _us.Display_Name;}
        roleAssignmentsInfo.users.push(body);
      });
    }
    return roleAssignmentsInfo;
  }

  initializeRevokeRoleFromUserList(roleid, user) {
    const revokedRoleInfo = {
      roleId: '',
      users: [
        {
          id: user.Id,
          first_Name: user.First_Name,
          last_Name: user.Last_Name,
          display_Name: user.Display_Name,
          login_Name: user.Login_Name,
          email_Address: user.Email_Address
        }
      ]
    };
    if (roleid) {revokedRoleInfo.roleId = roleid;}
    return revokedRoleInfo;
  }

  initializeRaMarkAsComplete(roadmapInfo, level) {
    const markAsCompleteInfo = {
      roadmapId: '',
      serviceMachineId: '',
      maskAsComplete: true
    };
    if (roadmapInfo.id) {markAsCompleteInfo.roadmapId = roadmapInfo.id;}
    if (level == 'machine level' && roadmapInfo.serviceMachineId) {markAsCompleteInfo.serviceMachineId = roadmapInfo.serviceMachineId;}
    if (level == 'service level' && roadmapInfo.serviceId) {markAsCompleteInfo.serviceMachineId = roadmapInfo.serviceId;}

    return markAsCompleteInfo;
  }

  initializeGroupManagementInfoToAdd(groupName, users) {
    const groupListInfo = {
      group_id: '',
      group: {
        id: '',
        group_name: '',
        group_email_address: ''
      },
      users: []
    };
    if (groupName) {groupListInfo.group.group_name = groupName;}
    if (isNotEmptyArray(users)) {
      users.forEach(_us => {
        const body = {
          id: _us.Id,
          first_Name: _us.First_Name,
          last_Name: _us.Last_Name,
          display_Name: _us.Display_Name,
          login_Name: _us.Login_Name,
          email_Address: _us.Email_Address
        };
        groupListInfo.users.push(body);
      });
    }
    return groupListInfo;
  }

  initializeRevokeGroupFromUserList(groupInfo, user) {
    const revokedGroupInfo = {
      group: {
        id: groupInfo.id,
        group_name: groupInfo.group_name,
        group_email_address: groupInfo.group_email_address
      },
      users: [
        {
          id: user.Id,
          first_Name: user.First_Name,
          last_Name: user.Last_Name,
          display_Name: user.Display_Name,
          login_Name: user.Login_Name,
          email_Address: user.Email_Address
        }
      ]
    };
    return revokedGroupInfo;
  }

  intitializeEditGroupInfo(groupName, groupId, groupEmail?) {
    const editPayload = {
      id: '',
      group_name: '',
      group_email_address: ''
    };
    if (groupName) {editPayload.group_name = groupName;}
    if (groupId) {editPayload.id = groupId;}
    if (groupEmail) {editPayload.group_email_address = groupEmail;}
    return editPayload;
  }

  intitializeEditAllRoleInfo(roleName, roleId) {
    const editAllRoleInfo = {
      id: '',
      role_name: '',
      isDefault: true,
      isGlobalAdminRole: false
    };
    const user = this.userAuth.getUserList();
    if (user && user.isGlobalAdmin) {editAllRoleInfo.isGlobalAdminRole = user.isGlobalAdmin;}
    if (roleName) {editAllRoleInfo.role_name = roleName;}
    if (roleId) {editAllRoleInfo.id = roleId;}
    return editAllRoleInfo;
  }

  initializeAddUsersInGroup(groupId, groupName, users) {
    const groupListInfo = {
      group: {
        id: '',
        group_name: '',
        group_email_address: ''
      },
      users: []
    };
    if (groupId) {groupListInfo.group.id = groupId;}
    if (groupName) {groupListInfo.group.group_name = groupName;}
    if (isNotEmptyArray(users)) {
      users.forEach(_us => {
        const body = {
          id: _us.Id,
          first_Name: _us.First_Name,
          last_Name: _us.Last_Name,
          display_Name: _us.Display_Name,
          login_Name: _us.Login_Name,
          email_Address: _us.Email_Address
        };
        groupListInfo.users.push(body);
      });
    }
    return groupListInfo;
  }

  constructLogActualHoursLists(machineListInfo) {
    const logActualHoursInfo = [];
    if (isNotEmptyArray(machineListInfo)) {
      machineListInfo.forEach(_ml => {
        const body = {
          open: false,
          edit: false,
          machine_Id: _ml.machine_Id,
          machinename: _ml.machine_Name,
          machine_id: _ml.machine_Id,
          serialNo: _ml.serial_Number,
          assetNo: _ml.asset_Id,
          calculatedHours: _ml.calculatedHours ? _ml.calculatedHours : 0,
          // calculatedHours: _ml.logActualHours ? _ml.logActualHours.calculatedHours : 0,
          comments: _ml.logActualHours ? _ml.logActualHours.comments : '',
          aggregateHours: _ml.aggregatedHours ? _ml.aggregatedHours : 0,
          serviceMachineId: _ml.serviceMachine_Id?.serviceMachineId ? _ml.serviceMachine_Id?.serviceMachineId : ''
        };
        logActualHoursInfo.push(body);
      });
    }
    return logActualHoursInfo;
  }

  initializeLogActualHours(projectId, orderid, serviceid, logActualHoursMachineLists, type) {
    const logActualHoursInfo = {
      project_Id: '',
      order_Id: '',
      service_Id: '',
      serviceMachine_Id: '',
      user_Id: '',
      user_Name: '',
      machineActualHours: []
    };
    const user = this.userAuth.getUserList();
    if (projectId) {logActualHoursInfo.project_Id = projectId;}
    if (orderid) {logActualHoursInfo.order_Id = orderid;}
    if (serviceid) {logActualHoursInfo.service_Id = serviceid;}
    if (user && user.userId) {
      logActualHoursInfo.user_Id = user.userId;
      logActualHoursInfo.user_Name = user.name;
    }
    if (isNotEmptyArray(logActualHoursMachineLists) && user && user.userId) {
      logActualHoursMachineLists.forEach(_lahl => {
        const machineActHrsBody = {
          serviceMachine_Id: '',
          machine_Id: '',
          actualHours: 0,
          comments: '',
          action: type
        };
        machineActHrsBody.serviceMachine_Id = _lahl.serviceMachine_Id;
        machineActHrsBody.machine_Id = _lahl.machine_id;
        if (_lahl.newActualHours) {machineActHrsBody.actualHours = +_lahl.newActualHours;}
        if (_lahl.newComments) {machineActHrsBody.comments = _lahl.newComments;}
        logActualHoursInfo.machineActualHours.push(machineActHrsBody);
      });
    }
    return logActualHoursInfo;
  }

  initializeResolvedConflictResolution(machinesLists, roadmapLists) {
    const body = {
      machine_Id: machinesLists.machine_Id,
      machine_Name: machinesLists.machine_Name,
      asset_Id: machinesLists.asset_Id,
      machine_Type: machinesLists.masterMachine ? machinesLists.masterMachine.machine_Type : '',
      industry: machinesLists.industry,
      masterMachine_Id: machinesLists.masterMachine_Id,
      serial_Number: machinesLists.serial_Number,
      service_Machine_Id: machinesLists.service_Machine_Id,
      roadmapDetails: [],
      isMachineConflict: false
    };
    if (isNotEmptyArray(roadmapLists)) {
      roadmapLists.forEach(_rml => {
        if (_rml.isConflict) {
          _rml.isMachineConflict = true;
        }
        delete _rml.eachQuesAnswered;
      });
      body.roadmapDetails = roadmapLists;
    }
    return body;
  }

  initializeRequestedFourEyeInformation(serviceMachineId, selectedUserId, hazardsInfo = []) {
    const body = {
      serviceMachine_Id: serviceMachineId,
      reviewer: '',
      hazards: []
    };
    if (selectedUserId) {body.reviewer = selectedUserId;}
    if (isNotEmptyArray(hazardsInfo)) {body.hazards = hazardsInfo;}
    return body;
  }

  async initializeFourEyeQualityMarkAsCompleteInformation(serviceMachine_Id) {
    const user = await this.userAuth.getUserList();
    const body = {
      markAsComplete: true,
      userId: user.userId,
      serviceMachine_Id,
    };
    return body;
  }

  initializeCustomRoadmapInfo(roadmapLists) {
    const roadmapInfo = [];
    if (isNotEmptyArray(roadmapLists)) {
      roadmapLists.forEach(_rmp => {
        const rPayload = {
          roadMapName: _rmp,
          roadMapVersion: '',
          description: '',
          isCustom: true
        };
        roadmapInfo.push(rPayload);
      });
    }
    return roadmapInfo;
  }

  initializeEditFourEyeHazardLists(recommendedCategory, 
    hazardid, 
    hazardInfo, 
    machineModeList,
    RiskResassessmentMachineMode, 
    riskMachineModesWithIds, 
    machinemodesWithIds, 
    hazardRatingList, 
    addHazardInfo, 
    hazardImagesList, 
    hazardSourceConnsequencesData,
    hazardDetailsData) {
    const hazardPayload = {
      id: hazardid,
      name: hazardInfo.hazardName,
      hazard_type_id: hazardid,
      initial_hazard: hazardInfo?.pickInitialHazard,
      counter_measure: hazardInfo?.pickControlMeasure,
      service_machine_steps_id: '',
      notes: hazardInfo?.notes ? hazardInfo?.notes : '',
      version: '',
      plr: hazardInfo?.determinePlrValue,
      category: recommendedCategory,
      hazardType: [],
      hazardMedia: hazardImagesList,
      hrnCalculations: [],
      plrObj: hazardDetailsData.plrObj
    };
    // Object.entries(hazardInfo.hazardTypesData).forEach((item, index) => {
    //   let hrnList = item[1];
    //   hazardTypeList.push(hrnList)
    // })
    if (isNotEmptyArray(addHazardInfo)) {
      addHazardInfo.forEach(_ahi => {
        const payload = {
          hazardTypeId: '',
          hazardTypeName: '',
          hazardSources: [],
          hazardConsequences: []
        };
        payload.hazardTypeId = _ahi?.hazardTypeId;
        payload.hazardTypeName = _ahi?.hazardType?.hazardTypeName;
        if (isNotEmptyArray(_ahi.source)) {
          if (hazardSourceConnsequencesData[0].source.length) {
            _ahi.source.forEach((_hs) => {
              const hazardSource = {
                id: '',
                source: ''
              };
              hazardSource.source = _hs?.source;
              hazardSourceConnsequencesData[0].source.forEach((src) => {
                if (src.source == _hs?.source) {
                  hazardSource.id = src.hazardSourceId;
                }
              });
              payload.hazardSources.push(hazardSource);
            });

          } else {
            _ahi.source.forEach((_hs) => {
              const hazardSource = {
                id: '',
                source: ''
              };
              hazardSource.source = _hs?.source;
              if (_hs.id) {hazardSource.id = _hs?.hazardSourceId;}
              payload.hazardSources.push(hazardSource);
            });
          }
        }
        if (isNotEmptyArray(_ahi.consequences)) {
          if (hazardSourceConnsequencesData[0].consequences.length) {
            _ahi.consequences.forEach(_hc => {
              const hazardConsequence = {
                id: '',
                consequences: ''
              };
              hazardConsequence.consequences = _hc?.consequence;
              hazardSourceConnsequencesData[0].consequences.forEach((consequence) => {
                if (consequence.consequence == _hc?.consequence) {
                  hazardConsequence.id = consequence.hazardConsequenceId;
                }
              });
              payload.hazardConsequences.push(hazardConsequence);
            });

          } else {
            _ahi.consequences.forEach(_hc => {
              const hazardConsequence = {
                id: '',
                consequences: ''
              };
              if (_hc.id) {hazardConsequence.id = _hc?.hazardConsequenceId;}
              hazardConsequence.consequences = _hc?.consequence;
              payload.hazardConsequences.push(hazardConsequence);
            });
          }

        }
        hazardPayload.hazardType.push(payload);
      });
    }
    if (isNotEmptyArray(RiskResassessmentMachineMode)) {
      const currentHrnPayload = {
        type: 'Risk Reassessment Current',
        values: []
      };
      const indicativeHrnPayload = {
        type: 'Risk Reassessment Indicative',
        values: []
      };
      RiskResassessmentMachineMode.forEach(_mm => {
        const _riskchrnValue = {
          id: '',
          po: '',
          sd: '',
          fe: '',
          np: '',
          rating: '',
          version: '',
          mode: ''
        };
        const _riskihrnValue = {
          id: '',
          po: '',
          sd: '',
          fe: '',
          np: '',
          rating: '',
          version: '',
          mode: ''
        };
        let iRating = 1;
        let cRating = 1;
        _riskchrnValue.mode = _mm;
        _riskihrnValue.mode = _mm;
        hazardRatingList.forEach(_hrl => {
          // const matchedChrnIds = machinemodesWithIds.find(_mmwi => { return _mmwi.mode == _mm && _mmwi.type == 'Current' })
          if (_hrl.dataRiskChrn) {//&& matchedChrnIds && matchedChrnIds.id
            Object.entries(_hrl.dataRiskChrn).forEach((item, index) => {
              if (item[0] == _mm) {
                const matchedChrnIds = riskMachineModesWithIds.find(_mmwi => _mmwi.mode == _mm && _mmwi.type == 'Risk Reassessment Current');
                // if (matchedChrnIds && matchedChrnIds.id) _chrnValue.id = matchedChrnIds.id
                if (matchedChrnIds && matchedChrnIds.id) {
                  _riskchrnValue.id = matchedChrnIds.id;
                } else {
                  _riskchrnValue.id = null;
                }
                // _chrnValue.mode = _mm;
                if (_hrl.val == 'PO') {
                  _riskchrnValue.po = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;
                }
                if (_hrl.val == 'SD') {
                  _riskchrnValue.sd = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'NP') {
                  _riskchrnValue.np = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'FE') {
                  _riskchrnValue.fe = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                _riskchrnValue.rating = JSON.stringify(cRating);
              }
            });
          }
          // const matchedIhrnIds = machinemodesWithIds.find(_mmwi => { return _mmwi.mode == _mm && _mmwi.type == 'Indicative' })
          if (_hrl.dataRiskIhrn) {//&& matchedIhrnIds && matchedIhrnIds.id
            Object.entries(_hrl.dataRiskIhrn).forEach((item, index) => {
              if (item[0] == _mm) {
                const matchedIhrnIds = riskMachineModesWithIds.find(_mmwi => _mmwi.mode == _mm && _mmwi.type == 'Risk Reassessment Indicative');
                // if (matchedIhrnIds && matchedIhrnIds.id) _ihrnValue.id = matchedIhrnIds.id
                if (matchedIhrnIds && matchedIhrnIds.id) {
                  _riskihrnValue.id = matchedIhrnIds.id;
                } else {
                  _riskihrnValue.id = null;
                }
                if (_hrl.val == 'PO') {
                  _riskihrnValue.po = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);
                }
                if (_hrl.val == 'SD') {
                  _riskihrnValue.sd = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'NP') {
                  _riskihrnValue.np = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'FE') {
                  _riskihrnValue.fe = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                _riskihrnValue.rating = JSON.stringify(iRating);
              }
            });
          }
        });
        currentHrnPayload.values.push(_riskchrnValue);
        indicativeHrnPayload.values.push(_riskihrnValue);
      });
      hazardPayload.hrnCalculations.push(currentHrnPayload);
      hazardPayload.hrnCalculations.push(indicativeHrnPayload);
    }
    if (isNotEmptyArray(machineModeList)) {
      const currentHrnPayload = {
        type: 'Current',
        values: []
      };
      const indicativeHrnPayload = {
        type: 'Indicative',
        values: []
      };
      machineModeList.forEach(_mm => {
        const _chrnValue = {
          id: '',
          po: '',
          sd: '',
          fe: '',
          np: '',
          rating: '',
          version: '',
          mode: ''
        };
        const _ihrnValue = {
          id: '',
          po: '',
          sd: '',
          fe: '',
          np: '',
          rating: '',
          version: '',
          mode: ''
        };
        let iRating = 1;
        let cRating = 1;
        _chrnValue.mode = _mm;
        _ihrnValue.mode = _mm;
        hazardRatingList.forEach(_hrl => {
          // const matchedChrnIds = machinemodesWithIds.find(_mmwi => { return _mmwi.mode == _mm && _mmwi.type == 'Current' })
          if (_hrl.dataChrn) {//&& matchedChrnIds && matchedChrnIds.id
            Object.entries(_hrl.dataChrn).forEach((item, index) => {
              if (item[0] == _mm) {
                const matchedChrnIds = machinemodesWithIds.find(_mmwi => _mmwi.mode == _mm && _mmwi.type == 'Current');
                // if (matchedChrnIds && matchedChrnIds.id) _chrnValue.id = matchedChrnIds.id
                if (matchedChrnIds && matchedChrnIds.id) {
                  _chrnValue.id = matchedChrnIds.id;
                } else {
                  _chrnValue.id = null;
                }
                // _chrnValue.mode = _mm;
                if (_hrl.val == 'PO') {
                  _chrnValue.po = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;
                }
                if (_hrl.val == 'SD') {
                  _chrnValue.sd = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'NP') {
                  _chrnValue.np = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'FE') {
                  _chrnValue.fe = item[1]['id']
                  cRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                _chrnValue.rating = JSON.stringify(cRating);
              }
            });
          }
          // const matchedIhrnIds = machinemodesWithIds.find(_mmwi => { return _mmwi.mode == _mm && _mmwi.type == 'Indicative' })
          if (_hrl.dataIhrn) {//&& matchedIhrnIds && matchedIhrnIds.id
            Object.entries(_hrl.dataIhrn).forEach((item, index) => {
              if (item[0] == _mm) {
                const matchedIhrnIds = machinemodesWithIds.find(_mmwi => _mmwi.mode == _mm && _mmwi.type == 'Indicative');
                // if (matchedIhrnIds && matchedIhrnIds.id) _ihrnValue.id = matchedIhrnIds.id
                if (matchedIhrnIds && matchedIhrnIds.id) {
                  _ihrnValue.id = matchedIhrnIds.id;
                } else {
                  _ihrnValue.id = null;
                }
                if (_hrl.val == 'PO') {
                  _ihrnValue.po = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);
                }
                if (_hrl.val == 'SD') {
                  _ihrnValue.sd = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'NP') {
                  _ihrnValue.np = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                if (_hrl.val == 'FE') {
                  _ihrnValue.fe = item[1]['id']
                  iRating *= item[1]['value'] ? JSON.parse(item[1]['value']) : 0;//JSON.parse(item[1]['value']);

                }
                _ihrnValue.rating = JSON.stringify(iRating);
              }
            });
          }
        });
        currentHrnPayload.values.push(_chrnValue);
        indicativeHrnPayload.values.push(_ihrnValue);
      });
      hazardPayload.hrnCalculations.push(currentHrnPayload);
      hazardPayload.hrnCalculations.push(indicativeHrnPayload);
    }
    return hazardPayload;
  }

  async showToast(toastTitle?: string, toastMessage?: string) {
    const toast = await this.toastController.create({
      header: toastTitle,
      message: toastMessage,
      cssClass: 'my-custom-toast',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async showWarningToast(toastTitle: string, toastMessage: string) {
    const toast = await this.toastController.create({
      header: toastTitle,
      message: toastMessage,
      cssClass: 'my-custom-toast',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }
  async errorShowToast(toastTitle?: string, toastMessage?: string) {
    const toast = await this.toastController.create({
      header: toastTitle,
      message: toastMessage,
      cssClass: 'my-custom-error-toast',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async closeBox() {
    const props = {
      isOfferCreation: true,
    };
    let msg: string;
    this.translate.get(AppConstants.ARE_YOU_SURE_TO_CANCEL).subscribe(res => {
      msg = res;
    });
    props['alertContent'] = msg;
    const modal = await this.modalController.create({
      component: AlertModalPage,
      cssClass: 'small-alert-modal',
      backdropDismiss: false,
      showBackdrop: false,
      componentProps: props,
    });
    await modal.present();
    const {
      // data: { isOfferCreationConfirmClose },
    } = await modal.onWillDismiss();
    // if (isOfferCreationConfirmClose) this.goBack();
  }


  compress(file: File): Observable<File> {
    // in bytes, compress images larger than 1MB
    const fileSizeMax = 1 * 1024 * 1024;
    // in pixels, compress images have the width or height larger than 1024px
    const widthHeightMax = 1024;
    const defaultWidthHeightRatio = 1;
    const defaultQualityRatio = 0.7;
    const imageType = file.type || 'image/jpeg';
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return Observable.create(observer => {
      // This event is triggered each time the reading operation is successfully completed.
      reader.onload = ev => {
        // Create an html image element
        const img = this.createImage(ev);
        // Choose the side (width or height) that longer than the other
        const imgWH = img.width > img.height ? img.width : img.height;

        // Determines the ratios to compress the image
        const withHeightRatio = (imgWH > widthHeightMax) ? widthHeightMax / imgWH : defaultWidthHeightRatio;
        const qualityRatio = (file.size > fileSizeMax) ? fileSizeMax / file.size : defaultQualityRatio;

        // Fires immediately after the browser loads the object
        img.onload = () => {
          const elem = document.createElement('canvas');
          // resize width, height
          elem.width = img.width * withHeightRatio;
          elem.height = img.height * withHeightRatio;

          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(
            // callback, called when blob created
            blob => {
              observer.next(new File(
                [blob],
                file.name,
                {
                  type: imageType,
                  lastModified: Date.now(),
                }
              ));
            },
            imageType,
            qualityRatio, // reduce image quantity
          );
        };
      };

      // Catch errors when reading file
      reader.onerror = error => observer.error(error);
    });
  }

  private createImage(ev) {
    const imageContent = ev.target.result;
    const img = new Image();
    img.src = imageContent;
    return img;
  }

  async uploadChunks(file, uploadUrl) {
    const reader = new FileReader();
    // Variables for byte stream position
    let position = 0;
    const chunkLength = 320 * 1024;
    let continueRead = true;
    while (continueRead) {
      var chunk;
      try {
        continueRead = true;
        //Try to read in the chunk
        try {
          const stopB = position + chunkLength;
          console.log('Sending Asynchronous request to read in chunk bytes from position ' + position + ' to end ' + stopB);
          chunk = await this.readFragmentAsync(file, position, stopB);
          console.log('UploadChunks: Chunk read in of ' + chunk.byteLength + ' bytes.');
          if (chunk.byteLength > 0) {
            continueRead = true;
          } else {
            break;
          }
          console.log('Chunk bytes received = ' + chunk.byteLength);
        } catch (e) {
          console.log('Bytes Received from readFragmentAsync:: ' + e);
          break;
        }
        // Try to upload the chunk.
        try {
          console.log('Request sent for uploadFragmentAsync');
          const res = await this.uploadChunk(chunk, uploadUrl, position, file.size);
          console.log('Response from uploadChunk', res);
          // Check the response.
          if (res[0] != 202 && res[0] != 201 && res[0] != 200)
            {throw ('Put operation did not return expected response');}
          if (res[0] === 201 || res[0] === 200) {
            console.log('Reached last chunk of file.  Status code is: ' + res[0]);
            continueRead = false;
            return res;
          }
          else {
            console.log('Continuing - Status Code is: ' + res[0]);
            position = Number(res[1].nextExpectedRanges[0].split('-')[0]);
          }

          console.log('Response received from uploadChunk.');
          console.log('Position is now ' + position);

        } catch (e) {
          console.log('Error occured when calling uploadChunk::' + e);
        }
        //
      } catch (e) {
        continueRead = false;
      }
    }
  }
  // Reads in the chunck and returns a promise.
  readFragmentAsync(file, startB, stopB) {
    let frag;
    const reader = new FileReader();
    console.log('startBytes :' + startB + ' stopBytes :' + stopB, file.slice(startB, stopB));
    const blob = file.slice(startB, stopB);
    reader.readAsArrayBuffer(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = (event) => {
        if (reader.readyState == reader.DONE) {
          frag = reader.result;
          resolve(frag);
        }
      };
    });
  }

  // Upload each chunk using PUT
  uploadChunk(chunk, uploadURL, position, totalLength) {//: Observable<any> {
    const max = position + chunk.byteLength - 1;
    // let contentLength = position + chunk.byteLength;
    const crHeader = `bytes ${position}-${max}/${totalLength}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Range': crHeader,
      })
    };
    // return this.http.put(uploadURL, chunk,httpOptions)

    return new Promise((resolve, reject) => {
      try {
        const crHeader = `bytes ${position}-${max}/${totalLength}`;
        this.http
          .put(uploadURL, chunk, httpOptions).subscribe((res: any) => {
            if (res.nextExpectedRanges && res.nextExpectedRanges.length) {
              resolve([202, res]);
            } else if (res.webUrl && res?.webUrl) {
              resolve([201, res]);
            } else {
              resolve([201, res]);
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get Countries
   */
  public getCountry() {
    const _self = this;
    _self.backendService
      .getCountry()
      .then((data: any) => {
        this.countryData = data.response;
        localStorage.setItem('countryData', JSON.stringify(data.response));
        this.countryList = data.response.map(item => ({ label: item.country_name, value: item.country_name }));
      });
  }

  /**
   * To get the formData of imageFiles
   * @param files
   * @param file_path
   * @returns 
   */
  public prepareImageFormData(formData, files, file_path): FormData {
    files.forEach(file => {
      formData.append('file_list', file);
      formData.append('file_path', file_path);
    });    
    return formData;
  }

  /**
   * To get the formData of thumbnail image
   * @param files 
   * @param thumbnailPath 
   * @returns 
   */
  public prepareThumbnailFormData(formData , files: File[], thumbnailPath: string): Promise<FormData> {
    const thumbnailPromises = files.map(file => 
      this.getThumbnailUrl(file, 800, 800)
        .then(thumbnailUrl => {
          if (thumbnailUrl) {
            return this.urlToFile(thumbnailUrl, file.name, file.type)
              .then(thumbnailFile => {
                // Append each thumbnail file to the FormData
                formData.append('file_list', thumbnailFile, thumbnailFile.name);
                formData.append('file_path', thumbnailPath);
                return thumbnailFile;
              });
          }
        })
        .catch(error => {
          console.error('Error generating thumbnail:', error);
        })
    );
  
    return Promise.all(thumbnailPromises)
      .then(() => {
        
        return formData;
      });
  }

  /**
   * To get the file object from url
   * @param url 
   * @param filename 
   * @param mimeType 
   * @returns 
   */
  private urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new File([blob], filename, { type: mimeType });
      });
  }

  /**
   * To get thumbnailUrl as per passed file, maxWidth and maxHeight
   * @param file 
   * @param maxWidth 
   * @param maxHeight 
   * @returns 
   */
  public getThumbnailUrl(file: File, maxWidth: number, maxHeight: number): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!file.type.startsWith('image/')) {
        reject('File is not an image.');
        return;
      }
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const thumbnailUrl = canvas.toDataURL('image/png');
          resolve(thumbnailUrl);
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  getHazardDropdownData() {
    this.backendService.gethazarddropdowndata(this.selectedLanguage).subscribe((data: any) => {
      if ((data.statusCode === 200)) {
        this.hazardDropdowndata = data.response.map(hazardType => ({
          ...hazardType,
          hazardConsequences: hazardType.hazardConsequences.map(consequence => ({
              hazardTypeId: consequence.hazardTypeId,
              hazardConsequenceId: consequence.hazardConsequenceId,
              consequences: consequence.hazardConsequenceName,
              consequence: consequence.hazardConsequenceName
          })),
          hazardSources: hazardType.hazardSources.map(source => ({
              hazardTypeId: source.hazardTypeId,
              hazardSourceId: source.hazardSourceId,
              source: source.hazardSourceName
          }))
        }));
      } else {
        this.toastService.presentToast(data.message, 4000, AppConstants.ERROR_SNACKBAR, 'top');
      }
    }, err => {
      this.toastService.presentToast('No data found for the hazard.', 3000, AppConstants.ERROR_SNACKBAR, 'top');
    })
  }

}